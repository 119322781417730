import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faBriefcase,
  faSpinner,
  faTimes,
  faTriangleExclamation,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  Breadcrumb,
  ButtonGroup,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  InputGroup,
  Button,
  Modal,
} from "react-bootstrap";
import { runData } from "../context/processor";
import { FaFilter } from "react-icons/fa";
import DOMPurify from "dompurify";
import { useUser } from "../hooks/useUser";

/**
 * Customers component displays a list of customers with pagination, search, and filtering functionality.
 * @component
 * @returns {JSX.Element} - The Customers component.
 */
const Customers = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 24;
  const { user, updateUser } = useUser();

  const slot = user?.slot; // Get user's slot information

  // State for the new customer modal
  const [showNewCustomerModal, setShowNewCustomerModal] = useState(false);
  const [newCustomerData, setNewCustomerData] = useState({
    company: "",
    description: "",
  });

  const handleNewCustomerInputChange = (e) => {
    const { name, value } = e.target;
    setNewCustomerData({ ...newCustomerData, [name]: value });
  };

  const handleNewCustomerSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const responseData = await runData(newCustomerData, `/customers/create`);
      if (responseData.data) {
        // After successful submission, reload the customer list
        setCustomers((prevCustomers) => [
          ...prevCustomers,
          responseData.data.customer,
        ]);
        setShowNewCustomerModal(false);
        setNewCustomerData({ company: "", description: "" });
      }
    } catch (error) {
      setError(DOMPurify.sanitize(error.message));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const pageData = async () => {
      try {
        const data = {};
        const responseData = await runData(data, `/customers/`);

        if (responseData.data) {
          const { code, customers, message } = responseData.data;
          if (code === 200) {
            setCustomers(
              customers.map((customer) => ({
                ...customer,
                company: DOMPurify.sanitize(customer.company),
                reference: DOMPurify.sanitize(customer.reference),
                status: customer.status,
              }))
            );
          } else {
            setError(DOMPurify.sanitize(message));
          }
        } else if (responseData.error) {
          setError(DOMPurify.sanitize(responseData.error.message));
        } else {
          throw new Error("Unexpected response structure");
        }
      } catch (err) {
        setError(DOMPurify.sanitize(err.message));
      } finally {
        setLoading(false);
      }
    };

    pageData();
  }, [slot]);

  const handleResetSearch = () => {
    setSearchQuery("");
  };

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const filteredCustomers = customers.filter((customer) =>
    customer.company.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const totalPages = Math.ceil(filteredCustomers.length / itemsPerPage);

  const handleGoToFirstPage = () => setCurrentPage(1);
  const handleGoToPreviousPage = () =>
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  const handleGoToNextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  const handleGoToLastPage = () => setCurrentPage(totalPages);

  const currentCustomers = filteredCustomers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-75">
        <Helmet>
          <title>Loading - Care Quality Support</title>
        </Helmet>
        <div>
          <FontAwesomeIcon icon={faSpinner} size="5x" spin />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <React.Fragment>
        <Helmet>
          <title>Error - Care Quality Support</title>
        </Helmet>
        <Container className="d-flex justify-content-center align-items-center vh-75 vertical-layout">
          <Row>
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              size="5x"
              className="text-warning"
            />
          </Row>
          <Row className="mt-4">
            <h4>{error}</h4>
          </Row>
        </Container>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Business Customers - Care Quality Support</title>
      </Helmet>
      <Container className="mb-4">
        <Row className="align-items-center mb-4">
          <Col className="mt-4">
            <Breadcrumb>
              <Breadcrumb.Item href="/dashboard/">Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item active>Customers</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col className="text-end">
            {/* Button to trigger the modal */}
            <Button
              className="bg-secondary text-light"
              onClick={() => setShowNewCustomerModal(true)}
            >
              <FontAwesomeIcon icon={faPlus} className="me-2" /> Add Customer
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="search" className="search-container mb-4">
              <InputGroup className="input-group-custom">
                <Form.Control
                  type="text"
                  placeholder="Filter customers by company"
                  value={searchQuery}
                  onChange={handleSearchQueryChange}
                  className="search-input"
                  autoComplete="off"
                />
                <Button variant="outline-secondary" className="icon-button">
                  <FaFilter />
                </Button>
                {searchQuery && (
                  <Button
                    variant="outline-secondary"
                    onClick={handleResetSearch}
                    className="icon-button"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                )}
              </InputGroup>
            </Form.Group>
            {filteredCustomers.length > 0 ? (
              <React.Fragment>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <div>{filteredCustomers.length} customers found</div>
                </div>
                <Row>
                  {currentCustomers.map((customer) => (
                    <Col
                      key={customer.reference}
                      sm={6}
                      md={4}
                      lg={3}
                      className="mb-4 mt-4"
                    >
                      <Card
                        className={`h-100 hover-zoom ${
                          customer.status === 0 ? "bg-danger" : "bg-light"
                        }`}
                      >
                        <Card.Body>
                          <div className="d-flex align-items-center">
                            <FontAwesomeIcon
                              icon={faBriefcase}
                              className="me-3 text-muted"
                              size="2x"
                            />
                            <div>
                              <Card.Title as={"p"} className="mb-0">
                                {customer.company}
                              </Card.Title>
                              <Card.Text className="text-muted">
                                ID: {customer.reference}
                              </Card.Text>
                            </div>
                          </div>
                          <Link
                            to={`/customers/${customer.reference}/`}
                            className="stretched-link"
                          ></Link>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
                {filteredCustomers.length > itemsPerPage && (
                  <div className="d-flex justify-content-between mt-4">
                    <div>
                      <Button
                        variant="secondary"
                        onClick={handleGoToFirstPage}
                        disabled={currentPage === 1}
                      >
                        <FontAwesomeIcon icon={faAngleDoubleLeft} /> First
                      </Button>
                    </div>
                    <div className="d-flex">
                      <Button
                        variant="secondary"
                        onClick={handleGoToPreviousPage}
                        disabled={currentPage === 1}
                        className="mx-2"
                      >
                        <FontAwesomeIcon icon={faAngleLeft} /> Previous
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={handleGoToNextPage}
                        disabled={currentPage === totalPages}
                        className="mx-2"
                      >
                        Next <FontAwesomeIcon icon={faAngleRight} />
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="secondary"
                        onClick={handleGoToLastPage}
                        disabled={currentPage === totalPages}
                      >
                        Last <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </Button>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ) : (
              <div className="text-center my-5">
                <h4>No customers found</h4>
                <Button
                  as={Link}
                  to="/organisation/new/customer/"
                  variant="primary"
                >
                  Create Customer
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </Container>

      {/* Modal for Adding New Customer */}
      <Modal
        show={showNewCustomerModal}
        onHide={() => setShowNewCustomerModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleNewCustomerSubmit}>
            {/* Company Name */}
            <Form.Group className="mb-3">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                name="company"
                value={newCustomerData.company}
                onChange={handleNewCustomerInputChange}
                required
              />
            </Form.Group>

            {/* Description */}
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={newCustomerData.description}
                onChange={handleNewCustomerInputChange}
                rows={3}
              />
            </Form.Group>

            {/* Form Buttons */}
            <div className="d-flex justify-content-between">
              <Button
                variant="secondary"
                onClick={() => setShowNewCustomerModal(false)}
              >
                Cancel
              </Button>
              <Button type="submit" variant="primary">
                Add Customer
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default Customers;
