import React from "react";
import PropTypes from "prop-types";
import { FaEdit } from "react-icons/fa";
import OwnershipRenderer from "./OwnershipRender";

/**
 * Renders the details content for a resource item, including title and description,
 * with conditional logic for showing ownership information if details are not shown.
 *
 * @param {object} props - The props for the component.
 * @param {object} props.item - The resource item object.
 * @param {number} props.slot - The user's current slot.
 * @param {Function} props.handleAction - The function to handle user actions.
 * @param {string} props.typeName - The type name of the resource.
 * @param {boolean} props.showDetails - Whether to show the detailed view.
 * @param {boolean} props.isDescriptionEmpty - Whether the item's description is empty.
 *
 * @returns {JSX.Element} The JSX representing the details content UI.
 */
const DetailsContentRenderer = ({
  item,
  slot,
  handleAction,
  typeName,
  showDetails,
  isDescriptionEmpty,
}) => {
  return (
    <div className="details-content flex-grow-1 mb-3">
      {showDetails ? (
        <>
          {/* Title Section */}
          <div className="mb-4">
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="fw-bold mb-0">Title</h6>
              {(item.resourceType === "global" && slot >= 1 && slot <= 6) ||
              (item.resourceType === "company" && slot >= 1 && slot <= 8) ||
              item.resourceType === "personal" ? (
                <FaEdit
                  style={{ cursor: "pointer" }}
                  onClick={() => handleAction("rename")}
                />
              ) : null}
            </div>
            <div className="text-muted mt-2" style={{ textAlign: "justify" }}>
              {item.title}
            </div>
          </div>

          {/* Description Section */}
          <div className="mb-4 mt-4">
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="fw-bold mb-0">Description</h6>
              {(item.resourceType === "global" && slot >= 1 && slot <= 6) ||
              (item.resourceType === "company" && slot >= 1 && slot <= 8) ||
              item.resourceType === "personal" ? (
                <FaEdit
                  style={{ cursor: "pointer" }}
                  onClick={() => handleAction("editDescription")}
                />
              ) : null}
            </div>
            {isDescriptionEmpty ? (
              <div className="text-muted mt-2" style={{ textAlign: "justify" }}>
                The description for this {typeName} is currently empty. To
                update it, please click the edit icon above. By doing so, you
                can provide detailed information about the content, structure,
                or purpose of this entry. A well-crafted description helps
                others to understand the context, usage, and any relevant
                details that may be associated with this {typeName}. Once you
                click the edit icon, a form or editor will appear, allowing you
                to enter the necessary details and save your changes.
              </div>
            ) : (
              <div
                className="text-muted mt-2"
                style={{ textAlign: "justify" }}
                dangerouslySetInnerHTML={{ __html: item.description }}
              ></div>
            )}
          </div>
        </>
      ) : (
        // Ownership Information Renderer
        <OwnershipRenderer
          item={item}
          slot={slot}
          handleAction={handleAction}
          typeName={typeName}
        />
      )}
    </div>
  );
};

// PropTypes validation
DetailsContentRenderer.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    resourceType: PropTypes.string.isRequired,
    shopping: PropTypes.string,
    price: PropTypes.string,
  }).isRequired,
  slot: PropTypes.number.isRequired,
  handleAction: PropTypes.func.isRequired,
  typeName: PropTypes.string.isRequired,
  showDetails: PropTypes.bool.isRequired,
  isDescriptionEmpty: PropTypes.bool.isRequired,
};

export default DetailsContentRenderer;
