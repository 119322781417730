import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { FaAngleLeft, FaFolderOpen } from "react-icons/fa";
import { Col } from "react-bootstrap";

/**
 * @component Header
 * @description Displays the back navigation link and the current directory title.
 * @param {Object} props
 * @param {String|null} props.parent - Reference ID of the parent directory.
 * @param {String} props.title - Title of the current directory.
 * @returns {JSX.Element}
 */
const Header = ({ parent, title }) => {
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * @function hasSubdirectory
   * @description Checks if the current path indicates a subdirectory.
   * @returns {boolean} True if there's a subdirectory, false otherwise.
   */
  const hasSubdirectory = () => {
    const pathParts = location.pathname.split("/directories/");
    return pathParts.length > 1 && pathParts[1] !== "";
  };

  /**
   * @function handleKeyDown
   * @description Handles the keydown event for Backspace navigation.
   * @param {KeyboardEvent} event - The keydown event.
   */
  const handleKeyDown = (event) => {
    // Avoid interfering when the user is typing
    const activeElement = document.activeElement;
    const isTyping =
      activeElement &&
      (activeElement.tagName === "INPUT" ||
        activeElement.tagName === "TEXTAREA" ||
        activeElement.isContentEditable);
    if (isTyping) return;

    if (event.key === "Backspace") {
      event.preventDefault();

      /**
       * @function getTargetPath
       * @description Determines the target path for navigation when Backspace is pressed.
       * @returns {string} The computed target path.
       */
      const getTargetPath = () => {
        if (hasSubdirectory()) {
          return parent && parent !== "null"
            ? `/directories/${parent}`
            : "/directories/";
        } else {
          return "/";
        }
      };

      navigate(getTargetPath());
    }
  };

  // Attach the keydown event listener and update it on `parent` or `location` changes
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [parent, location]);

  return (
    <React.Fragment>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className="d-flex align-items-center" style={{ gap: "1.5rem" }}>
          {hasSubdirectory() ? (
            <NavLink
              title="Back"
              style={{
                textDecoration: "none",
                color: "inherit",
                borderRight: "solid 2px red",
              }}
              className="active"
              to={
                parent && parent !== "null"
                  ? `/directories/${parent}/`
                  : "/directories/"
              }
            >
              <FaAngleLeft style={{ marginRight: "0.5rem" }} /> Back
            </NavLink>
          ) : (
            <NavLink
              className="active"
              title="Back"
              style={{
                textDecoration: "none",
                color: "inherit",
                borderRight: "solid 2px red",
              }}
              to={`/`}
            >
              <FaAngleLeft style={{ marginRight: "0.5rem" }} /> Back
            </NavLink>
          )}
          <Col>
            <h2>
              {title} <FaFolderOpen className="ms-2" />
            </h2>
            <p>Manage your folders and files in this directory.</p>
          </Col>
        </div>
      </div>
      <hr />
    </React.Fragment>
  );
};

Header.propTypes = {
  parent: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Header;
