// DownloadModal.jsx
import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";

/**
 * @component DownloadModal
 * @description Modal for confirming download of an item.
 * @param {Object} props
 * @param {Object|null} props.item - The item to download.
 * @param {Boolean} props.show - Visibility state of the modal.
 * @param {Function} props.handleClose - Function to close the modal.
 * @param {Function} props.onConfirm - Function to confirm the download action.
 * @returns {JSX.Element}
 */
const DownloadModal = ({ item, show, handleClose, onConfirm }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Download</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to download the item "{item?.title}"?
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="success" onClick={() => onConfirm("download")}>
          Download
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DownloadModal.propTypes = {
  item: PropTypes.object,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default DownloadModal;
