import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form } from "react-bootstrap";

/**
 * @component RenameModal
 * @description Modal for renaming an item.
 * @param {Object} props
 * @param {Object|null} props.item - The item to rename.
 * @param {Boolean} props.show - Visibility state of the modal.
 * @param {Function} props.handleClose - Function to close the modal.
 * @param {Function} props.onConfirm - Function to confirm the rename action.
 * @returns {JSX.Element}
 */
const RenameModal = ({ item, show, handleClose, onConfirm }) => {
  const [renameValue, setRenameValue] = useState("");

  // Effect to set renameValue when modal is shown
  useEffect(() => {
    if (show) {
      setRenameValue(item?.title || "");
    }
  }, [show, item?.title]);

  // Effect to reset renameValue when modal is hidden
  useEffect(() => {
    if (!show) {
      setRenameValue("");
    }
  }, [show]);

  const onSubmit = (e) => {
    e.preventDefault();
    const trimmedValue = renameValue.trim();
    if (trimmedValue) {
      onConfirm(trimmedValue); // Pass only the new title as a string
      handleClose(); // Close the modal immediately
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            Rename {item?.type === "folder" ? "Folder" : "File"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="renameForm">
            <Form.Label>New Name</Form.Label>
            <Form.Control
              type="text"
              value={renameValue}
              onChange={(e) => setRenameValue(e.target.value)}
              placeholder="Enter new name"
              required
              autoFocus
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={!renameValue.trim()}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

RenameModal.propTypes = {
  item: PropTypes.shape({
    reference: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default RenameModal;
