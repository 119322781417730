import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useReport } from "../context/ReportModal";
import { useProcessing } from "../context/ProcessingModal";
import { runData } from "../context/processor";
import DOMPurify from "dompurify";
import { addItemToDirectory } from "./directoriesData";
import { FaFolderPlus, FaPlus } from "react-icons/fa";
import PropTypes from "prop-types";

/**
 * Create Component
 *
 * @param {string} new - The type of item to create ("document" or "directory").
 * This component renders a button and modal to create a new directory or document.
 */
const Create = ({ new: newType }) => {
  // State variables for form inputs and modal visibility
  const [showModal, setShowModal] = useState(false);
  const [newPackageTitle, setNewPackageTitle] = useState("");
  const [accessType, setAccessType] = useState("");
  const [allowedLevels, setAllowedLevels] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [isDocument, setIsDocument] = useState(false); // Toggle between directory and document creation

  // Extract directory parameter from the URL
  const { directory } = useParams();

  // Context hooks for reporting and processing states
  const { setReport } = useReport();
  const { setProcessing } = useProcessing();

  /**
   * Function to handle showing the modal
   */
  const handleShowModal = (type) => {
    setIsReady(false);
    setIsDocument(type === "document");
    setShowModal(true);
    fetchAllowedLevels(type);
  };

  /**
   * Function to handle closing the modal
   */
  const handleCloseModal = () => {
    setShowModal(false);
    setNewPackageTitle("");
    setAccessType("");
    setAllowedLevels([]);
  };

  /**
   * Function to fetch allowed permission levels
   */
  const fetchAllowedLevels = async (type) => {
    setProcessing(true);
    try {
      const data = {
        parent: directory || "root",
        action: "create",
        type: type || "directory",
      };

      const response = await runData(data, `/api/directories/permissions/`);

      if (response.status === 200 && response.data?.code === 200) {
        setAllowedLevels(response.data.allowedLevels || []);
        setIsReady(true);
      } else {
        setReport({
          show: true,
          message: response.data?.message || "Failed to fetch allowed levels.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message: err.message || "An unexpected error occurred.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  /**
   * Function to handle form submission for creating a directory
   */
  const handleAddDirectory = async (e) => {
    e.preventDefault();
    handleCloseModal();

    if (!newPackageTitle.trim()) {
      setReport({
        show: true,
        message: "Directory title cannot be empty.",
        type: "error",
      });
      return;
    }

    if (!accessType.trim()) {
      setReport({
        show: true,
        message: "Access type must be selected.",
        type: "error",
      });
      return;
    }

    if (!allowedLevels.includes(accessType.toLowerCase())) {
      setReport({
        show: true,
        message: "You are not authorized to create this type of directory.",
        type: "error",
      });
      return;
    }

    setProcessing(true);
    try {
      const parent = directory || "root";
      const data = {
        title: newPackageTitle,
        access: accessType,
        type: "directory",
        parent: parent,
      };

      const response = await runData(data, `/api/directories/new/directory/`);

      if (response.status === 200 && response.data?.code === 200) {
        setNewPackageTitle("");
        setAccessType("");

        const newDirectory = {
          title: DOMPurify.sanitize(response.data.title),
          reference: response.data.reference,
          resourceType: accessType.toLowerCase(),
          type: "folder",
          parent_reference: parent,
          shopping: "no",
          price: "0.00",
        };

        addItemToDirectory(parent, newDirectory, "directories");

        setReport({
          show: true,
          message: "Your directory has been added successfully!",
          type: "success",
        });
      } else {
        setReport({
          show: true,
          message: response.data?.message || "Failed to add directory.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message: err.message || "An unexpected error occurred.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  /**
   * Function to handle form submission for creating a document
   */
  const handleAddDocument = async (e) => {
    e.preventDefault();
    handleCloseModal();

    if (!newPackageTitle.trim()) {
      setReport({
        show: true,
        message: "Document title cannot be empty.",
        type: "error",
      });
      return;
    }

    if (!accessType.trim()) {
      setReport({
        show: true,
        message: "Access type must be selected.",
        type: "error",
      });
      return;
    }

    if (!allowedLevels.includes(accessType.toLowerCase())) {
      setReport({
        show: true,
        message: "You are not authorized to create this type of document.",
        type: "error",
      });
      return;
    }

    setProcessing(true);
    try {
      const parent = directory || "root";
      const data = {
        title: newPackageTitle,
        access: accessType,
        type: "document",
        parent: parent,
      };

      const response = await runData(data, `/api/directories/new/document/`);

      if (response.status === 200 && response.data?.code === 200) {
        setNewPackageTitle("");
        setAccessType("");

        const newDocument = {
          title: DOMPurify.sanitize(response.data.title),
          reference: response.data.reference,
          resourceType: accessType.toLowerCase(),
          type: "document",
          parent_reference: parent,
          shopping: "no",
          price: "0.00",
          data_type: "write",
          status: 2,
        };

        addItemToDirectory(parent, newDocument, "documents");

        setReport({
          show: true,
          message: "Your document has been added successfully!",
          type: "success",
        });
      } else {
        setReport({
          show: true,
          message: response.data?.message || "Failed to add document.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message: err.message || "An unexpected error occurred.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  /**
   * Render the Create Component
   */
  return (
    <>
      {/* Buttons to open the modal */}
      {(!newType || newType === "directory") && (
        <Button
          variant="outline-primary"
          onClick={() => handleShowModal("directory")}
          size="sm"
        >
          <FaFolderPlus /> Directory
        </Button>
      )}

      {(!newType || newType === "document") && (
        <Button
          variant="outline-secondary"
          onClick={() => handleShowModal("document")}
          size="sm"
        >
          <FaPlus /> Document
        </Button>
      )}

      {/* Modal for creating a directory or document */}
      {showModal && isReady && (
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              {isDocument ? "Create New Document" : "Create New Directory"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              onSubmit={isDocument ? handleAddDocument : handleAddDirectory}
            >
              {/* Title input field */}
              <Form.Group className="mb-3">
                <Form.Label>
                  {isDocument ? "Document Title" : "Directory Title"}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={`Enter ${
                    isDocument ? "document" : "directory"
                  } title`}
                  value={newPackageTitle}
                  onChange={(e) => setNewPackageTitle(e.target.value)}
                  required
                />
              </Form.Group>

              {/* Access Type dropdown */}
              <Form.Group className="mb-3">
                <Form.Label>Access Type</Form.Label>
                <Form.Control
                  as="select"
                  value={accessType}
                  onChange={(e) => setAccessType(e.target.value)}
                  required
                >
                  <option value="">Select...</option>
                  {allowedLevels.includes("global") && (
                    <option value="Global">Global</option>
                  )}
                  {allowedLevels.includes("company") && (
                    <option value="Company">Company</option>
                  )}
                  {allowedLevels.includes("personal") && (
                    <option value="Personal">Personal</option>
                  )}
                </Form.Control>
              </Form.Group>

              {/* Action buttons */}
              <div className="d-flex justify-content-between">
                <Button variant="secondary" onClick={handleCloseModal}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={!newPackageTitle || !accessType}
                >
                  Add {isDocument ? "Document" : "Directory"}
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

Create.propTypes = {
  new: PropTypes.oneOf(["document", "directory"]),
};

export default Create;
