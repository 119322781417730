/**
 * @module BlankPage
 * @description A blank page providing buttons to create or navigate.
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaAngleUp } from "react-icons/fa";
import { Button, Container, Row, Col } from "react-bootstrap";
import PasteModal from "./modals/PasteModal";
import Create from "./Create";
import Upload from "./modals/Upload";

/**
 * @function BlankPage
 * @description Renders a blank area with creation and navigation buttons.
 * @param {object} props - The component props
 * @param {string} props.parent - The parent directory ID
 */
const BlankPage = ({ parent }) => {
  const params = useParams();
  const navigate = useNavigate();

  /**
   * @function handleKeyDown
   * @description Handles the keydown event for Backspace navigation.
   * @param {KeyboardEvent} event - The keydown event.
   */
  const handleKeyDown = (event) => {
    // Avoid interfering when the user is typing.
    const activeElement = document.activeElement;
    const isTyping =
      activeElement &&
      (activeElement.tagName === "INPUT" ||
        activeElement.tagName === "TEXTAREA" ||
        activeElement.isContentEditable);
    if (isTyping) return;

    if (event.key === "Backspace") {
      event.preventDefault();
      const getTargetPath = () =>
        parent && parent !== "null"
          ? `/directories/${parent}/`
          : "/directories/";
      navigate(getTargetPath());
    }
  };

  // Attach and clean up the keydown event listener.
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [params, navigate]);

  return (
    <Container
      className="d-flex flex-column align-items-center justify-content-center vh-75 text-center"
    >
      <div className="mb-4 mt-5">
        <h2 className="text-secondary mb-5 mt-5">
          This area is empty. Let's create something!
        </h2>

        <Row className="g-4 justify-content-center">
          <Col xs="12" md="auto">
            <PasteModal
              parent={params.directory || "root"}
              buttonProps={{
                variant: "outline-primary",
                size: "sm",
                className: "w-100",
              }}
            />
          </Col>
          <Col xs="12" md="auto">
            <Create new="directory" />
          </Col>
          <Col xs="12" md="auto">
            <Upload />
          </Col>
          <Col xs="12" md="auto">
            <Create new="document" />
          </Col>
          {params?.directory && (
            <Col xs="12" md="auto">
              <Link
                to={
                  parent && parent !== "null"
                    ? `/directories/${parent}/`
                    : "/directories/"
                }
                style={{ textDecoration: "none" }}
              >
                <Button variant="outline-dark" size="sm" className="w-100">
                  <FaAngleUp size={20} style={{ marginRight: "10px" }} /> Maybe
                  Later
                </Button>
              </Link>
            </Col>
          )}
        </Row>
      </div>
    </Container>
  );
};

BlankPage.propTypes = {
  parent: PropTypes.string,
};

export default BlankPage;
