import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import { FaFolder, FaFileAlt } from "react-icons/fa";

/**
 * @component ItemsGrid
 * @description Displays a grid of directory and file items with highlighting and color differentiation.
 * @param {Object} props
 * @param {Array} props.items - Array of directory and file items.
 * @param {Function} props.onItemClick - Function to handle item click.
 * @param {Function} props.onItemDoubleClick - Function to handle item double-click.
 * @param {Object} props.selectedItem - The currently selected item.
 * @returns {JSX.Element}
 */
const ItemsGrid = ({ items, onItemClick, onItemDoubleClick, selectedItem }) => {
  /**
   * @function getBackgroundColor
   * @description Determines the background color based on resourceType and selection status.
   * @param {Object} item - The current item.
   * @returns {String} - The background color.
   */
  const getBackgroundColor = (item) => {
    if (selectedItem && selectedItem.reference === item.reference) {
      return "#d1ecf1"; // Highlight color for selected items
    }

    switch (item.resourceType) {
      case "global":
        return "#e8f5e9"; // Light green for global
      case "company":
        return "#e3f2fd"; // Light blue for company
      case "personal":
        return "#fce4ec"; // Light pink for personal
      default:
        return "#ffffff"; // Default white
    }
  };

  /**
   * @function getBorderColor
   * @description Determines the border color based on item type.
   * @param {Object} item - The current item.
   * @returns {String} - The border color.
   */
  const getBorderColor = (item) => {
    return item.type === "folder" ? "#6c757d" : "#343a40"; // Different border colors for folders and files
  };

  return (
    <Row>
      {items.map((item) => (
        <Col
          key={item.reference}
          sm={6}
          md={4}
          lg={3}
          className="mb-4"
          onClick={() => onItemClick(item)}
          onDoubleClick={() => onItemDoubleClick(item)}
        >
          <div
            className="folder-file-container position-relative"
            style={{
              padding: "15px",
              textAlign: "center",
              borderRadius: "8px",
              boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
              cursor: "pointer",
              margin: "10px",
              userSelect: "none",
              backgroundColor: getBackgroundColor(item), // Set background color based on access type and selection
              border: `2px solid ${getBorderColor(item)}`, // Set border color based on type
              transition: "background-color 0.3s ease, border-color 0.3s ease", // Smooth transition
            }}
          >
            {item.type === "folder" ? (
              <FaFolder
                size={80}
                className="mb-2"
                style={{ color: "#6c757d" }}
              />
            ) : (
              <FaFileAlt
                size={80}
                className="mb-2"
                style={{ color: "#6c757d" }}
              />
            )}
            <div className="title small-text">
              <small>{item.title}</small>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
};

ItemsGrid.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      reference: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      resourceType: PropTypes.string, // Include resourceType in props validation
    })
  ).isRequired,
  onItemClick: PropTypes.func.isRequired,
  onItemDoubleClick: PropTypes.func.isRequired,
  selectedItem: PropTypes.object, // Include selectedItem in props validation
};

export default ItemsGrid;
