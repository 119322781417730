/**
 * @document DashboardPage.jsx
 * @description A professionally designed, fully responsive dashboard page
 *              for managing directories, documents, personal statistics, and balances.
 *              Uses the same runData approach for API calls and
 *              includes Restricted, ErrorPage, and Processing components
 *              for consistent loading/error handling.
 *
 *              Updates requested:
 *              - Format dates as "01 January 2025" (dayjs).
 *              - Align table action buttons to the far right.
 *              - Show amounts with two decimal places (e.g., 10.00).
 *              - Use JSDoc comments.
 *
 * @author
 *   Masimba Maregedze
 * @version 1.0.0
 */

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Button,
  Badge,
  Accordion,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaFileAlt, FaFolder, FaPoundSign } from "react-icons/fa";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import dayjs from "dayjs";

// Local imports (adjust paths as necessary)
import Processing from "../includes/Processing";
import ErrorPage from "../includes/ErrorPage";
import Restricted from "../includes/Restricted";
import { runData } from "../context/processor";

// Register the required elements
Chart.register(ArcElement, Tooltip, Legend);

/**
 * Formats a date string (e.g., "2025-01-06T12:00:00Z") to "01 January 2025".
 * @param {string} dateString - The date string to be formatted.
 * @returns {string} The formatted date.
 */
const formatCustomDate = (dateString) => {
  if (!dateString) return "";
  return dayjs(dateString).format("DD MMMM YYYY");
};

/**
 * Renders table rows for directory or document data.
 * Displays a "Nothing found" message if the array is empty.
 *
 * @param {Array} data - An array of objects representing either directories or documents.
 * @param {string[]} columns - The column keys to display in each row.
 * @param {Function} actionGenerator - Callback to render the action (e.g., 'View'/'Open' button).
 * @returns {JSX.Element[]} A list of table rows or a 'Nothing found' row when data is empty.
 */
const renderTableRows = (data, columns, actionGenerator) => {
  if (!data || data.length === 0) {
    return (
      <tr>
        <td colSpan={columns.length + 1} style={{ textAlign: "center" }}>
          Nothing found
        </td>
      </tr>
    );
  }

  return data.map((item, index) => (
    <tr key={index}>
      {columns.map((col) => {
        // If the column is a date field (e.g., "lastModified"), format it.
        let cellValue = item[col];
        if (col === "lastModified" && cellValue) {
          cellValue = formatCustomDate(cellValue);
        }
        return <td key={`${col}-${index}`}>{cellValue}</td>;
      })}
      {/* Align action generator content to the far right */}
      <td style={{ textAlign: "right" }}>{actionGenerator(item)}</td>
    </tr>
  ));
};

/**
 * @function DashboardPage
 * @description Fetches and displays user-specific stats (directories, documents, usage chart),
 *              using runData for API calls and Restricted/Error/Processing components
 *              for consistent loading/error handling. Renders a card-based layout
 *              with collapsible sections for directories, documents, and chart statistics.
 * @returns {JSX.Element} A fully responsive and production-ready dashboard component.
 */
const DashboardPage = () => {
  // ------------------------------------------------------------
  // State for loading, errors, restriction, plus dashboard data
  // ------------------------------------------------------------
  const [viewState, setViewState] = useState({
    loading: true,
    error: null,
    isRestricted: false,
  });

  // Basic data
  const [companyName, setCompanyName] = useState("");
  const [balance, setBalance] = useState(0);

  // Counts
  const [directoriesCount, setDirectoriesCount] = useState(0);
  const [documentsCount, setDocumentsCount] = useState(0);

  // Recently updated lists
  const [recentDirectories, setRecentDirectories] = useState([]);
  const [recentDocuments, setRecentDocuments] = useState([]);

  // Chart Data
  const [directoryStatsData, setDirectoryStatsData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  });

  // Chart options
  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
  };

  /**
   * Fetch dashboard data from the API upon component mount.
   * Uses the runData approach with { code, data, message } structure.
   */
  useEffect(() => {
    (async () => {
      try {
        // This object can be anything you might send to your /api/dashboard/ endpoint
        const requestData = {};

        // runData is the function used in 'Directories.jsx', adjusting endpoint
        const result = await runData(requestData, "/api/dashboard/");

        if (result?.data?.code === 200) {
          const dashInfo = result.data.dashboard;
          if (dashInfo) {
            // Populate state from response
            setCompanyName(dashInfo.companyName || "Organization");
            setBalance(dashInfo.balance || 0);
            setDirectoriesCount(dashInfo.directoriesCount || 0);
            setDocumentsCount(dashInfo.documentsCount || 0);

            setRecentDirectories(dashInfo.recentDirectories || []);
            setRecentDocuments(dashInfo.recentDocuments || []);

            // Generate random colors for the chart
            const colorArray = (dashInfo.chartLabels || []).map(() =>
              getRandomColor()
            );

            setDirectoryStatsData({
              labels: dashInfo.chartLabels || [],
              datasets: [
                {
                  data: dashInfo.chartValues || [],
                  backgroundColor: colorArray,
                },
              ],
            });

            // Done loading
            setViewState({ loading: false, error: null, isRestricted: false });
          } else {
            // If there's no "dashboard" object or missing data
            setViewState({
              loading: false,
              error: "Missing dashboard data in API response.",
              isRestricted: false,
            });
          }
        } else if (result?.data?.code === 402) {
          // 402 used for 'Restricted'
          setViewState({ loading: false, error: null, isRestricted: true });
        } else {
          // Some other error
          const errorMsg =
            result?.data?.message ||
            "An error occurred while fetching dashboard data.";
          setViewState({
            loading: false,
            error: errorMsg,
            isRestricted: false,
          });
        }
      } catch (err) {
        // If runData or fetch throws an error
        setViewState({
          loading: false,
          error: err.message || "An unexpected error occurred.",
          isRestricted: false,
        });
      }
    })();
  }, []);

  // ----------------------------------------------------------
  // Random color generator for chart segments (private helper)
  // ----------------------------------------------------------
  /**
   * @function getRandomColor
   * @description Generates a random hex color code (e.g., "#A1B2C3").
   * @returns {string} A random hex color code.
   */
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // -------------------------------------
  // Conditional Rendering
  // -------------------------------------
  // 1. Loading
  if (viewState.loading) {
    return <Processing />;
  }

  // 2. Error
  if (viewState.error) {
    return <ErrorPage error={viewState.error} />;
  }

  // 3. Restricted
  if (viewState.isRestricted) {
    return <Restricted product="" />;
  }

  // -------------------------------------
  // Normal Render
  // -------------------------------------
  return (
    <Container fluid className="py-4">
      <Helmet>
        <title>Dashboard - Documents & Directories</title>
      </Helmet>

      {/* Top Information Section */}
      <Row className="mb-4">
        <Col>
          <Card className="p-4 shadow-sm">
            <h4 className="fw-bold">Hello, {companyName || "Company"}</h4>

            {/* Account Balances & Stats */}
            <Row className="mb-4 mt-5">
              {/* Account Balance */}
              <Col md={4} className="mb-3">
                <Card className="h-100 shadow-sm">
                  <Card.Body className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <FaPoundSign size={30} className="text-success me-3" />
                      <div>
                        <Card.Title className="mb-0">
                          Account Balance
                        </Card.Title>
                        <Card.Text className="mb-0">
                          £
                          {balance.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Card.Text>
                      </div>
                    </div>
                    <Link to={`/billing/`}>
                      <Button
                        variant="outline-success"
                        size="sm"
                        className="ms-3"
                      >
                        Billing
                      </Button>
                    </Link>
                  </Card.Body>
                </Card>
              </Col>

              {/* Directories */}
              <Col md={4} className="mb-3">
                <Card className="h-100 shadow-sm">
                  <Card.Body className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <FaFolder size={30} className="text-primary me-3" />
                      <div>
                        <Card.Title className="mb-0">Directories</Card.Title>
                        <Card.Text className="mb-0">
                          {directoriesCount}
                        </Card.Text>
                      </div>
                    </div>
                    <Link to={`/directories/`}>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        className="ms-3"
                      >
                        View All
                      </Button>
                    </Link>
                  </Card.Body>
                </Card>
              </Col>

              {/* Documents */}
              <Col md={4} className="mb-3">
                <Card className="h-100 shadow-sm">
                  <Card.Body className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <FaFileAlt size={30} className="text-warning me-3" />
                      <div>
                        <Card.Title className="mb-0">Documents</Card.Title>
                        <Card.Text className="mb-0">{documentsCount}</Card.Text>
                      </div>
                    </div>
                    <Link to={`/directories/`}>
                      <Button
                        variant="outline-warning"
                        size="sm"
                        className="ms-3"
                      >
                        View All
                      </Button>
                    </Link>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      {/* Collapsible Sections for Directories & Documents */}
      <Accordion defaultActiveKey={["0", "1", "2"]} alwaysOpen flush>
        {/* Recently Updated Directories */}
        <Accordion.Item eventKey="0" className="mb-4">
          <Accordion.Header className="fw-bold">
            Recently Updated Directories
          </Accordion.Header>
          <Accordion.Body>
            <Card className="p-3 shadow-sm">
              <h5 className="d-flex justify-content-between align-items-center mb-3">
                <span>Recent Directories</span>
                <Link to={`/directories/`}>
                  <Button variant="link" className="p-0">
                    All Directories
                  </Button>
                </Link>
              </h5>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Last Modified</th>
                    <th style={{ textAlign: "right" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {renderTableRows(
                    recentDirectories,
                    ["name", "lastModified"],
                    (item) => (
                      <Link to={`/directories/${item?.reference || ""}/`}>
                        <Button variant="outline-secondary" size="sm">
                          View
                        </Button>
                      </Link>
                    )
                  )}
                </tbody>
              </Table>
            </Card>
          </Accordion.Body>
        </Accordion.Item>

        {/* Recently Updated Documents */}
        <Accordion.Item eventKey="1" className="mb-4">
          <Accordion.Header className="fw-bold">
            Recently Updated Documents
          </Accordion.Header>
          <Accordion.Body>
            <Card className="p-3 shadow-sm">
              <h5 className="d-flex justify-content-between align-items-center mb-3">
                <span>Recent Documents</span>
                <Link to={`/directories/`}>
                  <Button variant="link" className="p-0">
                    All Documents
                  </Button>
                </Link>
              </h5>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Last Modified</th>
                    <th style={{ textAlign: "right" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {renderTableRows(
                    recentDocuments,
                    ["title", "lastModified"],
                    (item) => (
                      <Link
                        to={`/directories/${item?.parent || ""}#${
                          item?.reference || ""
                        }`}
                      >
                        <Button variant="outline-secondary" size="sm">
                          Open
                        </Button>
                      </Link>
                    )
                  )}
                </tbody>
              </Table>
            </Card>
          </Accordion.Body>
        </Accordion.Item>

        {/* Directory Statistics & Chart */}
        <Accordion.Item eventKey="2" className="mb-4">
          <Accordion.Header className="fw-bold">
            Documents Statistics
          </Accordion.Header>
          <Accordion.Body>
            <Row>
              {/* Chart Column */}
              <Col md={6}>
                <Card style={{ height: "300px" }} className="p-3 shadow-sm">
                  <Pie data={directoryStatsData} options={chartOptions} />
                </Card>
              </Col>

              {/* Directory Legend Column */}
              <Col md={6}>
                <Card
                  className="p-3 shadow-sm"
                  style={{ height: "300px", overflowY: "auto" }}
                >
                  <h5>Statistics</h5>
                  {directoryStatsData.labels.map((label, idx) => {
                    const color =
                      directoryStatsData.datasets[0].backgroundColor[idx] ||
                      "#CCC";
                    const value = directoryStatsData.datasets[0].data[idx] || 0;
                    return (
                      <div
                        key={label}
                        className="d-flex align-items-center mb-2"
                      >
                        <span
                          style={{
                            display: "inline-block",
                            width: "16px",
                            height: "16px",
                            backgroundColor: color,
                            marginRight: "8px",
                            borderRadius: "2px",
                          }}
                        />
                        <span>{label}</span>
                        <Badge bg="info" className="ms-auto" text="dark">
                          {value}
                        </Badge>
                      </div>
                    );
                  })}
                </Card>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
};

export default DashboardPage;
