/**
 * @file BillingPage.jsx
 * @description A React component that allows customers to manage their account balance and billing settings, download invoices, and make deposits.
 */

import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Table,
  Modal,
  Form,
  Badge,
  Alert,
  Spinner,
  Pagination,
} from "react-bootstrap";
import { FaAngleLeft, FaDownload, FaWallet } from "react-icons/fa";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const sampleInvoices = [
  {
    id: 1,
    date: "2025-01-15",
    dueDate: "2025-02-15",
    amount: 99.99,
    status: "Paid",
    description: "Monthly subscription payment",
    invoiceUrl: "#",
  },
  {
    id: 2,
    date: "2024-12-15",
    dueDate: "2025-01-15",
    amount: 89.99,
    status: "Paid",
    description: "Annual license renewal",
    invoiceUrl: "#",
  },
  {
    id: 3,
    date: "2024-11-15",
    dueDate: "2024-12-15",
    amount: 109.99,
    status: "Unpaid",
    description: "One-time setup fee",
    invoiceUrl: "#",
  },
];

const BillingPage = () => {
  // State Management
  const [balance, setBalance] = useState(250.0);
  const [billingInfo] = useState({
    paymentMethod: "Credit Card",
    billingAddress: "123 Main Street, City, Country",
  });
  const [invoices] = useState(sampleInvoices);
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [depositAmount, setDepositAmount] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState("");
  const [showConfirmPaymentModal, setShowConfirmPaymentModal] = useState(false);
  const [confirmInvoiceId, setConfirmInvoiceId] = useState(null);
  const [proofFile, setProofFile] = useState(null);
  const [showUseBalanceModal, setShowUseBalanceModal] = useState(false);
  const [currentInvoiceId, setCurrentInvoiceId] = useState(null);

  // Handler Functions
  const handleOpenDepositModal = () => {
    setDepositAmount("");
    setShowDepositModal(true);
  };
  const handleCloseDepositModal = () => {
    setShowDepositModal(false);
    setError(null);
    setSuccess(null);
  };
  const handleDepositAmountChange = (e) => {
    setDepositAmount(e.target.value);
  };
  const handleSubmitDeposit = (e) => {
    e.preventDefault();
    const amount = parseFloat(depositAmount);
    if (isNaN(amount) || amount <= 0) {
      setError("Please enter a valid amount greater than zero.");
      return;
    }
    setIsProcessing(true);
    setError(null);
    setSuccess(null);
    setTimeout(() => {
      setBalance((prevBalance) => prevBalance + amount);
      setIsProcessing(false);
      setSuccess(`Successfully deposited £${amount.toFixed(2)}.`);
      setShowDepositModal(false);
    }, 1500);
  };
  const handleDownloadInvoice = (invoiceUrl) => {
    alert("Invoice download functionality is not implemented in this demo.");
  };
  const handleSelectPaymentMethod = (option) => {
    setSelectedPaymentOption(option);
    setShowPaymentMethodModal(true);
  };
  const handleClosePaymentMethodModal = () => {
    setShowPaymentMethodModal(false);
    setSelectedPaymentOption("");
  };
  const handleOpenConfirmPaymentModal = (invoiceId) => {
    setConfirmInvoiceId(invoiceId);
    setProofFile(null);
    setShowConfirmPaymentModal(true);
  };
  const handleCloseConfirmPaymentModal = () => {
    setShowConfirmPaymentModal(false);
    setProofFile(null);
  };
  const handleProofFileChange = (e) => {
    const file = e.target.files[0];
    setProofFile(file);
  };
  const handleSubmitProof = (e) => {
    e.preventDefault();
    alert(`Proof of payment for invoice ${confirmInvoiceId} submitted.`);
    setShowConfirmPaymentModal(false);
  };
  const handleOpenUseBalanceModal = (invoiceId) => {
    setCurrentInvoiceId(invoiceId);
    setShowUseBalanceModal(true);
  };
  const handleCloseUseBalanceModal = () => {
    setShowUseBalanceModal(false);
    setCurrentInvoiceId(null);
  };
  const handleConfirmUseBalance = () => {
    alert(`Using balance to pay invoice ${currentInvoiceId}.`);
    setShowUseBalanceModal(false);
  };

  // Utility Functions
  const formatCurrency = (amount) => `£${amount.toFixed(2)}`;

  // Rendering Helpers
  const renderBalanceCard = () => (
    <Card className="mb-4 shadow-sm">
      <Card.Body>
        <Row>
          <Col xs={12} md={8}>
            <Card.Title>Account Balance</Card.Title>
            <Card.Text style={{ fontSize: "2rem", fontWeight: "bold" }}>
              {formatCurrency(balance)}
            </Card.Text>
          </Col>
          <Col
            xs={12}
            md={4}
            className="d-flex align-items-center justify-content-md-end mt-3 mt-md-0"
          >
            <Button variant="success" onClick={handleOpenDepositModal}>
              <FaWallet className="me-2" />
              Make a Deposit
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );

  const renderBillingSettingsCard = () => (
    <Card className="mb-4 shadow-sm">
      <Card.Body>
        <Row className="mb-3">
          <Col>
            <Card.Title>Banking Details</Card.Title>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <strong style={{ display: "block", marginBottom: "0.5rem" }}>
              Account Name:
            </strong>
            <p>GMT Networks Ltd, t/a Care Quality Support</p>
          </Col>
          <Col md={4}>
            <strong style={{ display: "block", marginBottom: "0.5rem" }}>
              Bank Name:
            </strong>
            <p>Metro Bank</p>
          </Col>
          <Col md={4}>
            <strong style={{ display: "block", marginBottom: "0.5rem" }}>
              Sort Code:
            </strong>
            <p>23-05-80</p>
          </Col>
          <Col md={4}>
            <strong style={{ display: "block", marginBottom: "0.5rem" }}>
              Account Number:
            </strong>
            <p>47640091</p>
          </Col>
          <Col md={4}>
            <strong style={{ display: "block", marginBottom: "0.5rem" }}>
              SWIFT/BIC:
            </strong>
            <p>MYMBGB2L</p>
          </Col>
          <Col md={4}>
            <strong style={{ display: "block", marginBottom: "0.5rem" }}>
              IBAN:
            </strong>
            <p>GB02MYMB23058047640091</p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );

  const renderAlerts = () => (
    <>
      {error && (
        <Alert variant="danger" onClose={() => setError(null)} dismissible>
          {error}
        </Alert>
      )}
      {success && (
        <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
          {success}
        </Alert>
      )}
    </>
  );

  const renderDepositModal = () => (
    <Modal show={showDepositModal} onHide={handleCloseDepositModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Make a Deposit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {renderAlerts()}
        <p>
          Please note that all deposits are in <strong>GBP (£)</strong>. This deposit will be considered a
          <strong> top-up for document system access</strong>.
        </p>
        <Form onSubmit={handleSubmitDeposit}>
          <Form.Group controlId="depositAmount" className="mb-3">
            <Form.Label>Amount (GBP)</Form.Label>
            <Form.Control
              type="number"
              step="0.01"
              min="0.01"
              value={depositAmount}
              onChange={handleDepositAmountChange}
              placeholder="Enter amount to deposit"
              required
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          <Button variant="secondary" onClick={handleCloseDepositModal}>
            Close
          </Button>
          <Button
            variant="success"
            onClick={handleSubmitDeposit}
            disabled={isProcessing}
          >
            {isProcessing ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
                Processing...
              </>
            ) : (
              "Deposit Funds"
            )}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );

  const renderPaymentMethodModal = () => (
    <Modal show={showPaymentMethodModal} onHide={handleClosePaymentMethodModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>{selectedPaymentOption} Instructions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {selectedPaymentOption === "Bank Transfer" ? (
            <>
              Please transfer the funds to the following account:
              <br />
              <strong>Account Name:</strong> GMT Networks Ltd, t/a Care Quality Support <br />
              <strong>Bank Name:</strong> Metro Bank <br />
              <strong>Sort Code:</strong> 23-05-80 <br />
              <strong>Account Number:</strong> 47640091 <br />
              <strong>SWIFT/BIC:</strong> MYMBGB2L <br />
              <strong>IBAN:</strong> GB02MYMB23058047640091 <br />
            </>
          ) : (
            "For Manual Deposit, please follow the instructions provided by our support team."
          )}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          <Button variant="secondary" onClick={handleClosePaymentMethodModal}>
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );

  const renderConfirmPaymentModal = () => (
    <Modal show={showConfirmPaymentModal} onHide={handleCloseConfirmPaymentModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Payment Proof</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmitProof}>
        <Modal.Body>
          <p>Please upload an image or PDF as proof of payment:</p>
          <Form.Group controlId="proofFile" className="mb-3">
            <Form.Control
              type="file"
              accept="image/*,application/pdf"
              onChange={handleProofFileChange}
              required
            />
          </Form.Group>
          {proofFile && (
            <Alert variant="info">
              Selected File: {proofFile.name}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between w-100">
            <Button variant="secondary" onClick={handleCloseConfirmPaymentModal}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" disabled={!proofFile}>
              Submit Proof
            </Button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );

  const renderUseBalanceModal = () => (
    <Modal show={showUseBalanceModal} onHide={handleCloseUseBalanceModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Use Account Balance</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to use your account balance to pay this invoice?</p>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          <Button variant="secondary" onClick={handleCloseUseBalanceModal}>
            Cancel
          </Button>
          <Button variant="warning" onClick={handleConfirmUseBalance}>
            Confirm Payment
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );

  return (
    <Container fluid className="py-4">
      <Helmet>
        <title>Billing Management - Care Quality Support</title>
      </Helmet>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex align-items-center">
          <NavLink
            title="Back"
            className="active"
            style={{
              textDecoration: "none",
              color: "inherit",
              borderRight: "solid 2px red",
              marginRight: "10px",
            }}
            to={`/`}
          >
            <FaAngleLeft style={{ marginRight: "0.5rem" }} className="text-primary" /> Back
          </NavLink>
          <Col>
            <h2>
              Billing Management <FaWallet className="ms-2" />
            </h2>
            <p>Manage your account balance, billing settings, and invoices.</p>
          </Col>
        </div>
      </div>
      <hr />
      {renderAlerts()}
      {renderBalanceCard()}
      {renderBillingSettingsCard()}
      <InvoicesTable
        onOpenConfirmPaymentModal={handleOpenConfirmPaymentModal}
        onOpenUseBalanceModal={handleOpenUseBalanceModal}
      />
      {renderDepositModal()}
      {renderPaymentMethodModal()}
      {renderConfirmPaymentModal()}
      {renderUseBalanceModal()}
    </Container>
  );
};

/**
 * InvoicesTable Component
 */
const InvoicesTable = ({ onOpenConfirmPaymentModal, onOpenUseBalanceModal }) => {
  const [invoices] = useState(sampleInvoices);

  const handleDownloadInvoice = (invoiceUrl) => {
    alert("Invoice download functionality is not implemented in this demo.");
  };

  return (
    <Card className="mb-4 shadow-sm">
      <Card.Body>
        <Card.Title>Invoices</Card.Title>
        <Table striped bordered hover responsive className="mt-3">
          <thead className="table-dark">
            <tr>
              <th>#</th>
              <th>Date</th>
              <th>Due Date</th>
              <th>Description</th>
              <th>Amount</th>
              <th>Status</th>
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice, index) => (
              <tr key={invoice.id}>
                <td>{index + 1}</td>
                <td>{invoice.date}</td>
                <td>{invoice.dueDate}</td>
                <td>{invoice.description}</td>
                <td>{formatCurrency(invoice.amount)}</td>
                <td>
                  <Badge bg={invoice.status === "Paid" ? "success" : "warning"}>
                    {invoice.status}
                  </Badge>
                </td>
                <td>
                  <div className="d-flex justify-content-center flex-wrap gap-2">
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => handleDownloadInvoice(invoice.invoiceUrl)}
                    >
                      <FaDownload className="me-1" />
                      Download
                    </Button>
                    <Button
                      variant="warning"
                      size="sm"
                      onClick={() => onOpenUseBalanceModal(invoice.id)}
                    >
                      Use Balance
                    </Button>
                    <Button variant="secondary" size="sm" disabled>
                      Credit Card
                    </Button>
                    <Button variant="secondary" size="sm" disabled>
                      PayPal
                    </Button>
                    <Button
                      variant="success"
                      size="sm"
                      onClick={() => onOpenConfirmPaymentModal(invoice.id)}
                    >
                      Proof of Payment
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

const formatCurrency = (amount) => `£${amount.toFixed(2)}`;

const PaginationComponent = ({ currentPage, totalPages, onPageChange }) => {
  // ...Pagination logic remains unchanged...
};

PaginationComponent.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default BillingPage;
