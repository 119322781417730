import React, { useState, useEffect, useCallback, useRef } from "react";
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { FaAngleLeft, FaSave } from "react-icons/fa";
import { Helmet } from "react-helmet";

import { localData, runData } from "../context/processor";
import { useReport } from "../context/ReportModal";
import { useProcessing } from "../context/ProcessingModal";
import {
  deleteItemFromDirectory,
  editItemInDirectory,
} from "./directoriesData";
import Details from "./sections/Details";
import BundledEditor from "./helpers/editor";

/**
 * @component DocumentEditor
 * @description Main component that fetches and displays a document or folder along with a side panel.
 */
const DocumentEditor = () => {
  // State hooks
  const [item, setItem] = useState(null);
  const [title, setTitle] = useState("Document");
  const [content, setContent] = useState("");
  const [hasSavedChanges, setHasSavedChanges] = useState(false);
  const editorRef = useRef(null);
  const [lastExecuted, setLastExecuted] = useState(0);

  // Router and context hooks
  const params = useParams();
  const navigate = useNavigate();
  const { setReport } = useReport();
  const { setProcessing } = useProcessing();

  // Destructure parameters from URL
  const { directory, document: docRef } = params;

  /**
   * @function fetchItemDetails
   * @description Retrieves document/folder data from the API.
   */
  const fetchItemDetails = useCallback(async () => {
    setProcessing(true);
    try {
      const data = { reference: docRef };
      const response = await runData(data, `/api/directories/documents/view/`);
      if (response.status === 200 && response.data?.code === 200) {
        setTitle(response.data.document.title);
        setContent(response.data.document.content);
        setItem(response.data.document);
      } else {
        const errMsg = response.data?.message || "Failed to fetch details.";
        setReport({ show: true, message: errMsg, type: "error" });
      }
    } catch (err) {
      const errMsg = err.message || "An unexpected error occurred.";
      setReport({ show: true, message: errMsg, type: "error" });
    } finally {
      setProcessing(false);
    }
  }, [docRef, setReport, setProcessing]);

  // Check for unsaved changes in local storage on component mount.
  useEffect(() => {
    const initialize = async () => {
      const localContent = await localData("get", docRef);
      if (localContent) {
        setHasSavedChanges(true);
      }
      await fetchItemDetails();
    };
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docRef]);

  /**
   * @function handleItemUpdated
   * @description Handles updates (delete or modify) to the current item.
   * @param {string} command - The command type ('delete' or 'modify').
   * @param {object} updatedItem - The updated item data.
   */
  const handleItemUpdated = async (command, updatedItem) => {
    if (!item) return;

    const category = "documents";

    if (command === "delete") {
      await deleteItemFromDirectory(
        directory === "directory" ? "root" : directory,
        item.reference,
        category
      );
      const getTargetPath = () =>
        `/directories/${directory === "directory" ? "" : directory}`;
      navigate(getTargetPath());
    } else {
      await editItemInDirectory(
        directory === "directory" ? "root" : directory,
        item.reference,
        updatedItem,
        category
      );
      setItem(updatedItem);
    }
  };

  /**
   * @function handleKeyDown
   * @description Handles keyboard shortcuts including Ctrl/Cmd+S for saving and Backspace for navigation.
   * @param {KeyboardEvent} event - The keydown event.
   */
  const handleKeyDown = (event) => {
    // Always allow Ctrl/Cmd+S even if typing inside editor
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === "s") {
      event.preventDefault();
      save();
      return;
    }

    const activeElement = document.activeElement;
    const isTyping =
      activeElement &&
      (activeElement.tagName === "INPUT" ||
        activeElement.tagName === "TEXTAREA" ||
        activeElement.isContentEditable);
    if (isTyping) return;

    if (event.key === "Backspace") {
      event.preventDefault();
      const getTargetPath = () =>
        `/directories/${directory === "directory" ? "" : directory}`;
      navigate(getTargetPath());
    }
  };

  // Attach and clean up the keydown event listener.
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [directory, navigate]);

  /**
   * @function handleChange
   * @description Debounced change handler for editor content changes.
   */
  const handleChange = async () => {
    if (editorRef.current) {
      const currentTime = Date.now();
      if (currentTime - lastExecuted >= 30000) {
        const details = editorRef.current.getContent();
        await localData("save", docRef, details);
        setHasSavedChanges(true);
        setLastExecuted(currentTime);
      }
    }
  };

  /**
   * @function save
   * @description Handles the save functionality triggered by button click or keyboard shortcut.
   */
  const save = async () => {
    if (editorRef.current) {
      setProcessing(true);
      try {
        const data = {
          content: editorRef.current.getContent(),
          resource_reference: docRef,
          resource_type: "file",
          action: "edit",
        };
        const response = await runData(
          data,
          `/api/directories/edit/`
        );
        if (response.status === 200 && response.data?.code === 200) {
          await localData("remove", docRef);
          setHasSavedChanges(false);
          setReport({
            show: true,
            message: "Document saved successfully.",
            type: "success",
          });
        } else {
          const errMsg = response.data?.message || "Failed to fetch details.";
          setReport({ show: true, message: errMsg, type: "error" });
        }
      } catch (err) {
        const errMsg = err.message || "An unexpected error occurred.";
        setReport({ show: true, message: errMsg, type: "error" });
      } finally {
        setProcessing(false);
      }
    }
  };

  /**
   * @function restoreChanges
   * @description Restores the editor content from local storage if available.
   */
  const restoreChanges = async () => {
    const localContent = await localData("get", docRef);

    // Check if localContent exists and editorRef is ready
    if (localContent && editorRef.current) {
      try {
        // Set content to the editor
        editorRef.current.setContent(localContent);
        // Clear local storage and update state after successful restoration
        await localData("remove", docRef);
        setHasSavedChanges(false);
        setReport({
          show: true,
          message: "Changes restored from local storage.",
          type: "info",
        });
      } catch (error) {
        console.error("Failed to restore content:", error);
        setReport({
          show: true,
          message: "Failed to restore content.",
          type: "error",
        });
      }
    }
  };

  /**
   * @function renderLeftContent
   * @description Renders the main content area (left side) of the document.
   * @returns {JSX.Element} The rendered content.
   */
  const renderLeftContent = () => {
    if (!item) {
      return (
        <div className="text-center">
          <h5>Loading item details...</h5>
        </div>
      );
    }

    return (
      <div className="p-3 rounded" style={{ backgroundColor: "#ffffff" }}>
        <BundledEditor
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue={content}
          onEditorChange={handleChange}
          init={{
            branding: false,
            promotion: false,
            height: "75VH",
            menubar: true,
            plugins: [
              "advlist",
              "autolink",
              "link",
              "image",
              "lists",
              "charmap",
              "preview",
              "anchor",
              "pagebreak",
              "searchreplace",
              "wordcount",
              "visualblocks",
              "visualchars",
              "code",
              "fullscreen",
              "insertdatetime",
              "media",
              "table",
              "emoticons",
              "accordion",
              "autosave",
            ],
            toolbar:
              "undo redo | styles | fontfamily | fontsize | bold italic | alignleft aligncenter alignright alignjustify | " +
              "bullist numlist outdent indent | link image | print preview media fullscreen | " +
              "forecolor backcolor emoticons accordion restoredraft searchreplace",
            content_style:
              "body { font-family:Verdana,sans-serif; font-size:12px }",
            file_picker_types: "image",
            file_picker_callback: function (cb, value, meta) {
              const input = document.createElement("input");
              input.setAttribute("type", "file");
              input.setAttribute("accept", "image/*");
              input.onchange = function () {
                const file = this.files[0];
                const reader = new FileReader();
                reader.onload = function () {
                  cb(reader.result, { title: file.name });
                };
                reader.readAsDataURL(file);
              };
              input.click();
            },
          }}
        />
      </div>
    );
  };

  // Warn user of unsaved changes before exiting the page.
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasSavedChanges) {
        const confirmationMessage =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
      return undefined;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [hasSavedChanges]);

  return (
    <Container fluid>
      <Helmet>
        <title>{title} - Care Quality Support</title>
      </Helmet>
      <Row>
        <Col md={8} className="p-4 border-end">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div
              className="d-flex align-items-center"
              style={{ gap: "1.5rem" }}
            >
              <NavLink
                title="Back"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  borderRight: "solid 2px red",
                }}
                className="active"
                to={`/directories/${
                  directory === "directory" ? "" : directory
                }`}
              >
                <FaAngleLeft style={{ marginRight: "0.5rem" }} /> Back
              </NavLink>
              <Col>
                <h2 className="d-flex justify-content-between align-items-center">
                  Edit Online
                </h2>
                <p>{title}</p>
              </Col>
            </div>
            <div className="d-flex align-items-center" style={{ gap: "1rem" }}>
              {hasSavedChanges && (
                <Alert
                  variant="info"
                  className="mb-0 py-1 px-2 d-flex align-items-center"
                >
                  <span className="me-2">You have a local version.</span>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={restoreChanges}
                  >
                    Restore
                  </Button>
                </Alert>
              )}
              <Button
                variant="primary"
                onClick={save}
                title="Save Document"
                className="d-flex align-items-center"
              >
                <FaSave style={{ marginRight: "0.5rem" }} />
                Save
              </Button>
            </div>
          </div>
          <hr />
          {renderLeftContent()}
        </Col>
        <Col
          md={4}
          className="p-4 d-flex flex-column"
          style={{ height: "70vh", position: "sticky", top: "70px" }}
        >
          {item ? (
            <Details item={item} onItemUpdated={handleItemUpdated} />
          ) : (
            <h5 className="text-center">Load document to see the details</h5>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default DocumentEditor;
