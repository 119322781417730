/**
 * @function checkPermissions
 * @description Checks if the user has permission to perform a given action on a resource.
 * @param {Object} item - The resource item.
 * @param {String} action - The action to check (e.g., "edit", "delete", "copy", "share").
 * @param {Function} runData - Function to execute API calls.
 * @param {Function} setProcessing - Function to show/hide processing indicator.
 * @param {Function} setReport - Function to show report messages.
 * @returns {Promise<Boolean>} True if allowed, false otherwise.
 */
export async function checkPermissions(
  item,
  action,
  runData,
  setProcessing,
  setReport
) {
  setProcessing(true);
  try {
    const resourceType = item.type === "folder" ? "folder" : "file";
    const data = {
      resource_reference: item.reference,
      resource_type: resourceType,
      action: action,
    };

    const response = await runData(
      data,
      `/api/directories/permissions/confirm/`
    );

    if (
      response.status === 200 &&
      response.data?.code === 200 &&
      response.data.allowed
    ) {
      return true;
    } else {
      setReport({
        show: true,
        message:
          response.data?.message ||
          `You do not have permission to ${action} this ${resourceType}.`,
        type: "error",
      });
      return false;
    }
  } catch (err) {
    setReport({
      show: true,
      message:
        err.message ||
        "An unexpected error occurred while checking permissions.",
      type: "error",
    });
    return false;
  } finally {
    setProcessing(false);
  }
}
