import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink, useParams, useNavigate, Link } from "react-router-dom";
import DOMPurify from "dompurify";
import { FaAngleLeft, FaEdit } from "react-icons/fa";
import { Helmet } from "react-helmet";

import { runData } from "../context/processor";
import { useReport } from "../context/ReportModal";
import { useProcessing } from "../context/ProcessingModal";
import {
  deleteItemFromDirectory,
  editItemInDirectory,
} from "./directoriesData";
import Details from "./sections/Details";
import SignatureConfirmation from "./sections/Signature";
import { useUser } from "../hooks/useUser";

/**
 * @component DocumentDetails
 * @description Main component that fetches and displays a document or folder along with a side panel.
 */
const DocumentDetails = () => {
  // State hooks
  const [item, setItem] = useState(null);
  const [title, setTitle] = useState("Document");
  const [content, setContent] = useState("");
  const { user } = useUser();

  // Router and context hooks
  const params = useParams();
  const navigate = useNavigate();
  const { setReport } = useReport();
  const { setProcessing } = useProcessing();

  // Destructure parameters from URL
  const { directory, document: docRef } = params;

  /**
   * @function fetchItemDetails
   * @description Retrieves document/folder data from the API and navigates back on failure.
   * @returns {void}
   */
  const fetchItemDetails = useCallback(async () => {
    setProcessing(true);
    try {
      // Data payload for the API request
      const data = { reference: docRef };
      // Make the API call using the provided runData function
      const response = await runData(data, `/api/directories/documents/view/`);

      // Check if the response status and data code are successful
      if (response.status === 200 && response.data?.code === 200) {
        // Set state with retrieved document details
        setTitle(response.data.document.title);
        setContent(response.data.document.content);
        setItem(response.data.document);
      } else {
        // Determine error message from response or use default
        const errMsg = response.data?.message || "Failed to fetch details.";
        // Report the error to the user
        setReport({ show: true, message: errMsg, type: "error" });
        // Navigate back to the previous directory/page
        navigate(-1);
      }
    } catch (err) {
      // Handle unexpected errors during the fetch operation
      const errMsg = err.message || "An unexpected error occurred.";
      setReport({ show: true, message: errMsg, type: "error" });
      // Optionally, navigate back in case of an exception
      navigate(-1);
    } finally {
      // Indicate that processing has finished
      setProcessing(false);
    }
  }, [docRef, setReport, setProcessing, useNavigate]);

  // Fetch item details on component mount or when dependencies change.
  useEffect(() => {
    fetchItemDetails();
  }, [fetchItemDetails]);

  /**
   * @function handleItemUpdated
   * @description Handles updates (delete or modify) to the current item.
   * @param {string} command - The command type ('delete' or 'modify').
   * @param {object} updatedItem - The updated item data.
   */
  const handleItemUpdated = async (command, updatedItem) => {
    // Ensure item exists before proceeding.
    if (!item) return;

    const category = "documents";

    if (command === "delete") {
      await deleteItemFromDirectory(
        directory === "directory" ? "root" : directory,
        item.reference,
        category
      );
      const getTargetPath = () =>
        `/directories/${directory === "directory" ? "" : directory}`;
      navigate(getTargetPath());
    } else {
      await editItemInDirectory(
        directory === "directory" ? "root" : directory,
        item.reference,
        updatedItem,
        category
      );
      setItem(updatedItem);
    }
  };

  /**
   * @function handleKeyDown
   * @description Handles the keydown event for Backspace navigation.
   * @param {KeyboardEvent} event - The keydown event.
   */
  const handleKeyDown = (event) => {
    // Avoid interfering when the user is typing.
    const activeElement = document.activeElement;
    const isTyping =
      activeElement &&
      (activeElement.tagName === "INPUT" ||
        activeElement.tagName === "TEXTAREA" ||
        activeElement.isContentEditable);
    if (isTyping) return;

    if (event.key === "Backspace") {
      event.preventDefault();
      const getTargetPath = () =>
        `/directories/${directory === "directory" ? "" : directory}`;
      navigate(getTargetPath());
    }
  };

  // Attach and clean up the keydown event listener.
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [directory, navigate]);

  /**
   * @function renderLeftContent
   * @description Renders the main content area (left side) of the document.
   * @returns {JSX.Element} The rendered content.
   */
  const renderLeftContent = () => {
    if (!item) {
      return (
        <div className="text-center">
          <h5>Loading item details...</h5>
        </div>
      );
    }

    return (
      <div className="p-3 rounded" style={{ backgroundColor: "#ffffff" }}>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(content || "No content."),
          }}
        />
      </div>
    );
  };

  return (
    <Container fluid>
      <Helmet>
        <title>{title} - Care Quality Support</title>
      </Helmet>
      <Row>
        <Col md={8} className="p-4 border-end">
          <React.Fragment>
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div
                className="d-flex align-items-center"
                style={{ gap: "1.5rem" }}
              >
                <NavLink
                  title="Back"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    borderRight: "solid 2px red",
                  }}
                  className="active"
                  to={`/directories/${
                    directory === "directory" ? "" : directory
                  }`}
                >
                  <FaAngleLeft style={{ marginRight: "0.5rem" }} /> Back
                </NavLink>
                <Col>
                  <h2 className="d-flex justify-content-between align-items-center">
                    Read Online
                  </h2>
                  <p>{title}</p>
                </Col>
              </div>
              {
                /*
                 * Conditionally render the Edit button based on the following criteria:
                 * - Exclude rendering if item.shopping is "yes" and user slot is between 7 and 10.
                 * - For 'global' resources: allow slots 1 through 6.
                 * - For 'company' resources: allow slots 1 through 3, or exactly 7 or 8.
                 * - For 'personal' resources: allow all slots.
                 */
                item &&
                  user &&
                  // Exclude edit option if item.shopping is "yes" and user slot is in the range 7 to 10
                  !(
                    item.shopping === "yes" &&
                    user.slot >= 7 &&
                    user.slot <= 10
                  ) &&
                  ((item.resourceType === "global" &&
                    user.slot >= 1 &&
                    user.slot <= 6) ||
                    (item.resourceType === "company" &&
                      ((user.slot >= 1 && user.slot <= 3) ||
                        user.slot === 7 ||
                        user.slot === 8)) ||
                    item.resourceType === "personal") && (
                    <Link
                      to="edit/"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <FaEdit style={{ fontSize: "1.2em" }} /> Edit
                    </Link>
                  )
              }
            </div>
            <hr />
          </React.Fragment>
          {renderLeftContent()}
          <br />
          {
            /*
             * Conditionally render the SignatureConfirmation component based on:
             * - Hide if the resource is personal.
             * - Hide if the resource is global and user slot is between 1 and 6.
             * - Hide if the resource is company and user qualifies for editing company resources.
             */
            item &&
              user &&
              // Check negation of hide conditions to determine if SignatureConfirmation should be shown
              !(
                item.resourceType === "personal" ||
                (item.resourceType === "global" &&
                  user.slot >= 1 &&
                  user.slot <= 6) ||
                (item.resourceType === "company" &&
                  ((user.slot >= 1 && user.slot <= 3) ||
                    user.slot === 7 ||
                    user.slot === 8))
              ) && (
                <SignatureConfirmation
                  shouldShow={item.confirmed}
                  docRef={item.reference}
                  runData={runData}
                  setProcessing={setProcessing}
                  setReport={setReport}
                />
              )
          }
        </Col>
        <Col
          md={4}
          className="p-4 d-flex flex-column"
          style={{ height: "70vh", position: "sticky", top: "70px" }}
        >
          {/* Ensure item exists before rendering Details to avoid errors */}
          {item && item !== null ? (
            <Details item={item} onItemUpdated={handleItemUpdated} />
          ) : (
            <h5 className="text-center">Load document to see the details</h5>
          )}
        </Col>
      </Row>
    </Container>
  );
};

DocumentDetails.propTypes = {};

export default DocumentDetails;
