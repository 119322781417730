import React from "react";
import PropTypes from "prop-types";
import { Navigate, useLocation } from "react-router-dom";
import useSessionWatch from "../hooks/useSessionWatch";

/**
 * ProtectedRoute component that checks if the session is valid before rendering the protected component.
 * If the session is not valid, it redirects to the login page.
 * @component
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.element - The protected component to render.
 * @returns {JSX.Element} - The protected component, a loading spinner, or a redirect to the login page.
 */
const ProtectedRoute = ({ element }) => {
  const sessionValid = useSessionWatch();
  const location = useLocation();

  // If sessionValid is null, we are still checking the session, show a loading state
  if (sessionValid === null) {
    return <div>Loading...</div>; // You can replace this with a custom loading component
  }

  // If session is valid, render the protected component; otherwise, redirect to login
  return sessionValid ? (
    element
  ) : (
    <Navigate
      to={`/get-started/?redirect=${encodeURIComponent(location.pathname)}`}
      replace
    />
  );
};

ProtectedRoute.propTypes = {
  element: PropTypes.node.isRequired,
};

export default ProtectedRoute;
