import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Row, Col, Container } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import PasteModal from "./modals/PasteModal";
import Header from "./sections/Header";
import FilterAndPaginationControls from "./sections/Pagination";
import ItemsGrid from "./sections/Grid";
import Create from "./Create";
import Details from "./sections/Details";
import {
  deleteItemFromDirectory,
  editItemInDirectory,
} from "./directoriesData";
import Upload from "./modals/Upload";

/**
 * @component DirectoryFolders
 * @description Display a grid of directories and documents, supports viewing details,
 * editing metadata, managing pagination, filtering, and performing actions like sharing,
 * renaming, deleting, duplicating, and pasting.
 *
 * @param {Object} props
 * @param {Array} props.directories - Array of directory objects
 * @param {Array} props.documents - Array of file objects
 * @param {String} props.parent - Reference ID of the parent directory
 * @param {String} props.title - Title of the current directory
 *
 * @returns {JSX.Element}
 */
const DirectoryFolders = ({ directories, parent, title, documents }) => {
  const [selectedItem, setSelectedItem] = useState(null);

  // Pagination and Filtering State
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const itemsPerPageOptions = [6, 12, 24, 48];
  const [currentPage, setCurrentPage] = useState(1);
  const [filterType, setFilterType] = useState("All");

  // React Router Hooks
  const params = useParams();
  const navigate = useNavigate();

  // UI Refs
  const detailsRef = useRef(null);

  // Responsive State
  const [isMobile, setIsMobile] = useState(window.innerWidth < 850);

  const directoryPath = params.directory || "directory";

  // Pagination Calculations
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const totalItems = (() => {
    if (filterType === "Directories") return directories.length;
    if (filterType === "Documents") return documents.length;
    return directories.length + documents.length;
  })();
  const currentItems = (() => {
    if (filterType === "Directories")
      return directories.slice(indexOfFirstItem, indexOfLastItem);
    if (filterType === "Documents")
      return documents.slice(indexOfFirstItem, indexOfLastItem);
    return [...directories, ...documents].slice(
      indexOfFirstItem,
      indexOfLastItem
    );
  })();
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Pagination Handlers
  const goToNextPage = () =>
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  const goToPreviousPage = () =>
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  const goToFirstPage = () => setCurrentPage(1);
  const goToLastPage = () => setCurrentPage(totalPages);

  // Handle Window Resize for Mobile
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 850);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Smooth Scroll to Details on Mobile
  useEffect(() => {
    if (selectedItem && detailsRef.current && isMobile) {
      detailsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedItem, isMobile]);

  // Reset State When Filter Type Changes
  useEffect(() => setCurrentPage(1), [filterType]);

  // Reset Selected Item When URL Changes
  useEffect(() => setSelectedItem(null), [params]);

  // Handle Item Click
  const handleItemClick = (item) => setSelectedItem(item);

  // Handle Item Double Click (Navigate to Folder)
  const handleItemDoubleClick = (item) => {
    if (item.type === "folder") {
      navigate(`/directories/${item.reference}/`);
    } else {
      navigate(`/directories/${directoryPath}/${item.reference}/`);
    }
  };

  // Handle Updated Item from DetailsPanel. Command can either be delete or modify, if we delete selected item is null
  const handleItemUpdated = async (command, updatedItem) => {
    const category =
      updatedItem.type === "folder" ? "directories" : "documents";
    if (command === "delete") {
      await deleteItemFromDirectory(
        params.directory || "root",
        selectedItem.reference,
        category
      );
      setSelectedItem(null);
    } else {
      await editItemInDirectory(
        params.directory || "root",
        selectedItem.reference,
        updatedItem,
        category
      );
      setSelectedItem(updatedItem);
    }
  };

  // New Effect: Check URL hash on component mount to select item by reference if present
  useEffect(() => {
    const hash = window.location.hash.startsWith("#")
      ? window.location.hash.substring(1)
      : window.location.hash;

    if (hash) {
      const foundInDirectories = directories.find(
        (dir) => dir.reference === hash
      );
      const foundInDocuments = documents.find((doc) => doc.reference === hash);

      if (foundInDirectories) {
        setSelectedItem(foundInDirectories);
      } else if (foundInDocuments) {
        setSelectedItem(foundInDocuments);
      }
    }
  }, [directories, documents]);

  return (
    <Container fluid className="mb-4">
      <Row>
        <Col md={8} className="border-end">
          <Header parent={parent} title={title} />

          <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4">
            <FilterAndPaginationControls
              filterType={filterType}
              setFilterType={setFilterType}
              itemsPerPage={itemsPerPage}
              itemsPerPageOptions={itemsPerPageOptions}
              totalItems={totalItems}
              currentPage={currentPage}
              totalPages={totalPages}
              goToFirstPage={goToFirstPage}
              goToPreviousPage={goToPreviousPage}
              goToNextPage={goToNextPage}
              goToLastPage={goToLastPage}
              handleItemsPerPageChange={(newItemsPerPage) => {
                setItemsPerPage(newItemsPerPage);
                setCurrentPage(1);
              }}
            />

            <div
              className="d-flex align-items-center mt-3 mt-md-0"
              style={{ gap: "0.5rem" }}
            >
              <PasteModal
                parent={params.directory || "root"}
                buttonProps={{
                  variant: "outline-primary",
                  size: "sm",
                  className: "d-flex align-items-center",
                }}
              />
              <Create />
              <Upload />
            </div>
          </div>

          {totalItems > 0 ? (
            <ItemsGrid
              items={currentItems}
              onItemClick={handleItemClick}
              onItemDoubleClick={handleItemDoubleClick}
              selectedItem={selectedItem}
            />
          ) : (
            <div className="d-flex justify-content-center align-items-center vh-100 text-center">
              <h4>Nothing Found</h4>
            </div>
          )}
        </Col>
        <Col
          md={4}
          className="p-4 d-flex flex-column"
          style={{ height: "70vh", position: "sticky", top: "70px" }}
          ref={detailsRef}
        >
          {selectedItem ? (
            <Details item={selectedItem} onItemUpdated={handleItemUpdated} />
          ) : (
            <h5 className="text-center">Select an item to see the details</h5>
          )}
        </Col>
      </Row>
    </Container>
  );
};

DirectoryFolders.propTypes = {
  directories: PropTypes.arrayOf(
    PropTypes.shape({
      reference: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      reference: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      description: PropTypes.string,
    })
  ).isRequired,
  parent: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default DirectoryFolders;
