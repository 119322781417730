import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { FaUpload } from "react-icons/fa";
import { useReport } from "../../context/ReportModal";
import { useProcessing } from "../../context/ProcessingModal";
import { runData } from "../../context/processor";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import { addItemToDirectory } from "../directoriesData";

/**
 * Upload Component
 *
 * This component renders a button that opens a modal with a form for uploading documents.
 * It integrates with an API to upload the document and add it to the directory.
 */
const Upload = () => {
  // State variables
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [file, setFile] = useState(null);
  const [accessType, setAccessType] = useState("");
  const [allowedLevels, setAllowedLevels] = useState([]);
  const [isReady, setIsReady] = useState(false);

  // Context hooks for reporting and processing states
  const { setReport } = useReport();
  const { setProcessing } = useProcessing();
  // Extract directory parameter from the URL
  const { directory } = useParams();

  // Supported file types
  const acceptedFileTypes = [
    ".doc",
    ".docx",
    ".xls",
    ".xlsx",
    ".ppt",
    ".pptx",
    ".pdf",
  ];

  // Function to handle showing the modal
  const handleShow = () => {
    setIsReady(false);
    setShow(true);
    fetchAllowedLevels();
  };

  // Function to handle closing the modal
  const handleClose = () => {
    setShow(false);
    setAllowedLevels([]);
    setTitle("");
    setFile(null);
    setAccessType("");
  };

  // Function to handle file drop in Dropzone
  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (acceptedFileTypes.includes(`.${fileExtension}`)) {
        setFile(file);
      } else {
        alert(
          "Unsupported file type. Please upload a Microsoft Office file or a PDF."
        );
      }
    }
  };

  /**
   * fetchAllowedLevels Function
   *
   * Fetches the allowed permission levels from the server.
   * Displays the processing modal during the fetch.
   * Sets the form modal to be ready upon successful fetch.
   */
  const fetchAllowedLevels = async () => {
    setProcessing(true);
    try {
      const data = {
        parent: directory || "root",
        action: "create",
        type: "document",
      };

      const response = await runData(data, `/api/directories/permissions/`);

      if (response.status === 200 && response.data?.code === 200) {
        setAllowedLevels(response.data.allowedLevels || []);
        setIsReady(true);
      } else {
        setReport({
          show: true,
          message:
            response.data?.message ||
            "An error occurred during fetching allowed levels.",
          type: "error",
        });
        setAllowedLevels([]);
      }
    } catch (err) {
      setReport({
        show: true,
        message:
          err.message ||
          "An unexpected error occurred during fetching allowed levels.",
        type: "error",
      });
      setAllowedLevels([]);
    } finally {
      setProcessing(false);
    }
  };

  /**
   * handleUpload Function
   *
   * Handles the form submission for uploading the document.
   * Sends the file and metadata to the server via `runData`.
   */
  const handleUpload = async () => {
    if (!title.trim()) {
      setReport({
        show: true,
        message: "Title cannot be empty.",
        type: "error",
      });
      return;
    }
    if (!accessType.trim()) {
      setReport({
        show: true,
        message: "Access type must be selected.",
        type: "error",
      });
      return;
    }
    if (!allowedLevels.includes(accessType.toLowerCase())) {
      setReport({
        show: true,
        message: "You are not authorized to use this access type.",
        type: "error",
      });
      return;
    }
    handleClose();
    setProcessing(true);
    try {
      // Create the data object as per your instructions
      const data = {
        title: title.trim(),
        document: file,
        access: accessType,
        parent: directory || "root",
      };

      // Make the API call
      const response = await runData(data, `/api/directories/upload/document/`);

      if (response.status === 200 && response.data?.code === 200) {
        // Create a sanitized new document object
        const newDocument = {
          title: DOMPurify.sanitize(response.data.title),
          reference: response.data.reference,
          resourceType: accessType.toLowerCase(),
          type: "document",
          parent_reference: directory || "root",
          shopping: "no",
          price: "0.00",
          data_type: "upload",
          status: 2,
        };

        // Add the new document to the directory
        addItemToDirectory(directory || "root", newDocument, "documents");

        // Show success report
        setReport({
          show: true,
          message: "Your document has been uploaded successfully!",
          type: "success",
        });

        handleClose();
      } else {
        setReport({
          show: true,
          message:
            response.data?.message || "An error occurred during file upload.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message:
          err.message || "An unexpected error occurred during file upload.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      {/* Button to trigger the modal */}
      <Button variant="outline-info" onClick={handleShow} size="sm">
        <FaUpload /> Upload
      </Button>

      {/* Modal for the upload form */}
      {show && isReady && (
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Upload File</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {/* Title input field */}
              <Form.Group className="mb-3">
                <Form.Label>File Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </Form.Group>

              {/* Dropzone for file upload */}
              <Form.Group className="mb-3">
                <Form.Label>Attach File</Form.Label>
                <Dropzone onDrop={handleDrop} multiple={false}>
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps()}
                      style={{
                        border: "2px dashed #007bff",
                        padding: "20px",
                        textAlign: "center",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <input {...getInputProps()} />
                      {file ? (
                        <p>{file.name}</p>
                      ) : (
                        <p>Drag and drop a file here, or click to select one</p>
                      )}
                    </div>
                  )}
                </Dropzone>
              </Form.Group>

              {/* Access Type Dropdown */}
              <Form.Group className="mb-3">
                <Form.Label>Access Type</Form.Label>
                <Form.Control
                  as="select"
                  value={accessType}
                  onChange={(e) => setAccessType(e.target.value)}
                  required
                >
                  <option value="">Select...</option>
                  {allowedLevels.includes("global") && (
                    <option value="Global">Global</option>
                  )}
                  {allowedLevels.includes("company") && (
                    <option value="Company">Company</option>
                  )}
                  {allowedLevels.includes("personal") && (
                    <option value="Personal">Personal</option>
                  )}
                </Form.Control>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleUpload}
              disabled={!title || !file || !accessType}
            >
              Upload
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default Upload;
