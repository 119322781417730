import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";

/**
 * @component DeleteModal
 * @description Modal for confirming deletion of an item.
 * @param {Object} props
 * @param {Object|null} props.item - The item to delete.
 * @param {Boolean} props.show - Visibility state of the modal.
 * @param {Function} props.handleClose - Function to close the modal.
 * @param {Function} props.onConfirm - Function to confirm the delete action.
 * @returns {JSX.Element}
 */
const DeleteModal = ({ item, show, handleClose, onConfirm }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete the item "{item?.title}"? This action
        cannot be undone.
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            onConfirm({ transform: "delete" }); // Pass the delete payload directly
            handleClose(); // Close the modal immediately
          }}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DeleteModal.propTypes = {
  item: PropTypes.shape({
    reference: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default DeleteModal;
