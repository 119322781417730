// File: /src/context/processor.js

import axios from "axios";
import { initDB } from "../config/initDB";

/**
 * Axios instance for API requests with a default base URL and headers.
 */
export const apiData = axios.create({
  baseURL: "/query",
  headers: {
    "Content-Type": "multipart/form-data",
  },
  withCredentials: true, // Ensure that HTTP-only cookies are included
});

/**
 * Axios request interceptor to handle request configurations.
 */
apiData.interceptors.request.use(
  (config) => {
    // No longer setting the Authorization header; using HTTP-only cookies instead.
    return config;
  },
  (error) => {
    return Promise.reject(new Error(error.message));
  }
);

/**
 * Runs a POST request to the specified path with the provided data.
 * @param {Object} data - Data to be sent in the request.
 * @param {string} path - API endpoint path.
 * @param {string} [type] - Optional response type.
 * @returns {Promise} - Axios response promise.
 */
export async function runData(data, path, type) {
  const formData = new FormData();
  for (const key in data) {
    if (Object.hasOwn(data, key)) {
      formData.append(key, data[key]);
    }
  }

  const config = type ? { responseType: type } : {};

  try {
    return await apiData.post(path, formData, config);
  } catch (error) {
    let errorMessage;
    let statusCode;

    if (type === "blob") {
      errorMessage =
        "An error occurred while processing your request, please contact support.";
    } else {
      errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred.";
      statusCode = error.response?.status || 402;
    }
    throw new Errored(errorMessage, statusCode);
  }
}

/**
 * Runs a GET request to the specified path with the provided data.
 * @param {Object} data - Data to be sent in the request.
 * @param {string} path - API endpoint path.
 * @param {string} [type] - Optional response type.
 * @returns {Promise} - Axios response promise.
 */
export async function getData(data, path, type) {
  const config = { params: data };
  if (type) {
    config.responseType = type;
  }

  try {
    return await apiData.get(path, config);
  } catch (error) {
    let errorMessage;
    let statusCode;

    if (type === "blob") {
      errorMessage =
        "An error occurred while processing your request, please contact support.";
    } else {
      errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred.";
      statusCode = error.response?.status || 402;
    }
    throw new Errored(errorMessage, statusCode);
  }
}

/**
 * Manages IndexedDB settings actions.
 * @param {string} action - Action to perform: 'save', 'remove', 'get', 'update'.
 * @param {string} item - Item key.
 * @param {any} [value] - Value to save or update.
 * @returns {Promise<any>} - The result of the action.
 */
export async function localData(action, item, value) {
  const db = await initDB();
  const tx = db.transaction("settings", "readwrite");
  const store = tx.objectStore("settings");

  switch (action) {
    case "save": {
      await store.put({ setting: item, value });
      await tx.done;
      return true;
    }
    case "remove": {
      const existingItem = await store.get(item);
      if (existingItem) {
        await store.delete(item);
        await tx.done;
        return true;
      }
      return false;
    }
    case "get": {
      const data = await store.get(item);
      return data ? data.value : null;
    }
    case "update": {
      const existingItem = await store.get(item);
      if (existingItem) {
        await store.put({ setting: item, value });
        await tx.done;
        return true;
      }
      return false;
    }
    default:
      return null;
  }
}

/**
 * Manages cart data actions.
 * @param {string} action - Action to perform: 'save', 'remove', 'get', 'check', 'update', 'increase', 'decrease'.
 * @param {string} item - Product reference key.
 * @param {any} [value] - Value to save, update, or modify.
 * @returns {Promise<any>} - The result of the action.
 */
export async function cartData(action, item, value) {
  const db = await initDB();
  const tx = db.transaction("shopping", "readwrite");
  const store = tx.objectStore("shopping");

  switch (action) {
    case "save": {
      await store.put({ reference: item, ...value });
      await tx.done;
      window.dispatchEvent(new Event("cartChange"));
      return true;
    }
    case "remove": {
      const existingItem = await store.get(item);
      if (existingItem) {
        await store.delete(item);
        await tx.done;
        window.dispatchEvent(new Event("cartChange"));
        return true;
      }
      return false;
    }
    case "get": {
      const allItems = await store.getAll();
      return allItems;
    }
    case "check": {
      const existingItem = await store.get(item);
      return existingItem || false;
    }

    case "update": {
      const existingItem = await store.get(item);
      if (existingItem) {
        await store.put({ reference: item, ...value });
        await tx.done;
        window.dispatchEvent(new Event("cartChange"));
        return true;
      }
      return false;
    }
    case "increase": {
      const existingItem = await store.get(item);
      if (existingItem) {
        existingItem.quantity += value;
        await store.put(existingItem);
        await tx.done;
        window.dispatchEvent(new Event("cartChange"));
        return true;
      }
      return false;
    }
    case "decrease": {
      const existingItem = await store.get(item);
      if (existingItem && existingItem.quantity > 1) {
        existingItem.quantity -= value;
        await store.put(existingItem);
        await tx.done;
        window.dispatchEvent(new Event("cartChange"));
        return true;
      }
      return false;
    }
    default:
      return false;
  }
}

export class Errored extends Error {
  constructor(message, status) {
    super(message);
    this.status = status;
  }
}
