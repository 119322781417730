import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";

/**
 * @component StatusModal
 * @description Modal for changing the status of an item.
 * @param {Object} props
 * @param {Object|null} props.item - The item to change status.
 * @param {Boolean} props.show - Visibility state of the modal.
 * @param {Function} props.handleClose - Function to close the modal.
 * @param {Function} props.onConfirm - Function to confirm the change status action.
 * @returns {JSX.Element}
 */
const StatusModal = ({ item, show, handleClose, onConfirm }) => {
  // Determine the new status
  const newStatus = item?.status === 1 ? "suspend" : "activate";

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Change Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to {newStatus} the item "{item?.title}"?
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant={item.status === 1 ? "danger" : "success"}
          onClick={() => {
            onConfirm(newStatus); // Pass the new status directly
            handleClose(); // Close the modal immediately
          }}
        >
          {newStatus.toUpperCase()}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

StatusModal.propTypes = {
  item: PropTypes.shape({
    reference: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
  }),
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default StatusModal;
