import { saveAs } from "file-saver";
import { slugify } from "../../includes/slugify";

/**
 * @function downloadItemPDF
 * @description Downloads a file as a PDF.
 * @param {Object} item - The file item to download.
 * @param {Function} runData - Function to execute API calls.
 * @param {Function} setProcessing - Function to show/hide processing indicator.
 * @param {Function} setReport - Function to show report messages.
 */
export async function downloadItemPDF(item, runData, setProcessing, setReport) {
  setProcessing(true);
  try {
    const data = {
      reference: item.reference,
    };

    const response = await runData(
      data,
      `/api/directories/documents/download/`,
      "blob"
    );

    if (response.status === 200) {
      const filename = slugify(item.title);
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split("T")[0];
      const defaultFilename = `Download_${formattedDate}`;
      saveAs(blob, filename || defaultFilename);
      setReport({
        show: true,
        message: "Your download request has been successful!",
        type: "success",
      });
    } else {
      setReport({
        show: true,
        message:
          response.data?.message || "An error occurred during the download.",
        type: "error",
      });
    }
  } catch (err) {
    setReport({
      show: true,
      message: err.message || "An unexpected error occurred during download.",
      type: "error",
    });
  } finally {
    setProcessing(false);
  }
}
