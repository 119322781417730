import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

/**
 * @component EditDescriptionModal
 * @description Modal for editing an item's description.
 * @param {Object} props
 * @param {Object|null} props.item - The item to edit.
 * @param {Boolean} props.show - Visibility state of the modal.
 * @param {Function} props.handleClose - Function to close the modal.
 * @param {Function} props.onConfirm - Function to confirm the edit action.
 * @returns {JSX.Element}
 */
const EditDescriptionModal = ({ item, show, handleClose, onConfirm }) => {
  const [description, setDescription] = useState("");

  // Effect to set the description when the modal is shown
  useEffect(() => {
    if (show) {
      setDescription(item?.description || "");
    }
  }, [show, item?.description]);

  // Effect to reset the description when the modal is hidden
  useEffect(() => {
    if (!show) {
      setDescription("");
    }
  }, [show]);

  const onSubmit = (e) => {
    e.preventDefault();
    const trimmedDescription = description.trim();
    if (trimmedDescription) {
      onConfirm(trimmedDescription); // Pass only the new description as a string
      handleClose(); // Close the modal immediately
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="editDescriptionForm">
            <Form.Label>
              <strong>Description:</strong> {item?.title}
            </Form.Label>
            <ReactQuill
              value={description}
              onChange={setDescription}
              placeholder="Enter the description here..."
              modules={{
                toolbar: [
                  ["bold", "italic", "underline", "strike"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link"],
                  ["clean"],
                ],
              }}
              formats={[
                "bold",
                "italic",
                "underline",
                "strike",
                "list",
                "bullet",
                "link",
              ]}
              style={{ minHeight: "150px" }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={!description.trim()}
          >
            Save Description
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

EditDescriptionModal.propTypes = {
  item: PropTypes.shape({
    reference: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default EditDescriptionModal;
