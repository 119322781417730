import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";

/**
 * @component PurchaseModal
 * @description Modal to confirm purchase of an item.
 * @param {Object} props
 * @param {Object|null} props.item - The item to purchase.
 * @param {Boolean} props.show - Visibility state of the modal.
 * @param {Function} props.handleClose - Function to close the modal.
 * @param {Function} props.onConfirm - Function to confirm the purchase action.
 * @returns {JSX.Element}
 */
const PurchaseModal = ({ item, show, handleClose, onConfirm }) => {
  /**
   * @function handleConfirm
   * @description Handler for confirming the purchase.
   * Closes the modal after confirmation.
   */
  const handleConfirm = () => {
    onConfirm(item);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Purchase {item.type === "folder" ? "Directory" : "Document"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {item ? (
          <p>
            Are you sure you want to purchase this{" "}
            {item.type === "folder" ? "folder" : "document"} for&nbsp;
            <strong>£{item.price}</strong>?<br />
            <br />
            <strong>{item.title}</strong>
          </p>
        ) : (
          <p>Item information is not available.</p>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button variant="secondary" onClick={handleClose}>
          Maybe Later
        </Button>
        <Button variant="success" onClick={handleConfirm} disabled={!item}>
          Buy Now
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

PurchaseModal.propTypes = {
  item: PropTypes.shape({
    reference: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default PurchaseModal;
