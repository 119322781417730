// src/components/PasteModal.jsx

import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { FaPaste, FaFolder, FaFileAlt } from "react-icons/fa";
import { Button, Modal, Form } from "react-bootstrap";
import { localData, runData } from "../../context/processor";
import { useReport } from "../../context/ReportModal";
import { useProcessing } from "../../context/ProcessingModal";
import { addItemToDirectory } from "../directoriesData";
import DOMPurify from "dompurify";

/**
 * @component PasteModal
 * @description A reusable component that includes a "Paste Here" button and a confirmation modal.
 * It handles fetching the duplicate item, fetching allowed permission levels, and executing the paste action.
 *
 * @param {Object} props
 * @param {String} props.parent - Reference ID of the parent directory where the item will be pasted.
 * @param {Object} props.buttonProps - Additional props to customize the "Paste Here" button.
 *
 * @returns {JSX.Element}
 */
const PasteModal = ({ parent, buttonProps }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [duplicate, setDuplicate] = useState(null);
  const [allowedLevels, setAllowedLevels] = useState([]);
  const [accessType, setAccessType] = useState("");

  // Context Hooks
  const { setReport } = useReport();
  const { setProcessing } = useProcessing();

  /**
   * Fetch duplicate item from local storage.
   */
  const fetchDuplicate = useCallback(async () => {
    try {
      const result = await localData("get", "duplicate");
      if (result) {
        setDuplicate(result);
      } else {
        setDuplicate(null); // Clear duplicate if not found
      }
    } catch (error) {
      console.error("Error fetching duplicate:", error);
      setReport({
        show: true,
        message: "Error fetching duplicate item.",
        type: "error",
      });
    }
  }, [setReport]);

  /**
   * Fetch duplicate on component mount and when 'parent' changes.
   */
  useEffect(() => {
    fetchDuplicate();
  }, [parent, fetchDuplicate]);

  /**
   * Listen for the 'duplicateCompleted' event and re-fetch duplicate.
   */
  useEffect(() => {
    const handleDuplicateCompleted = (event) => {
      // Optionally, you can use event.detail.duplicatedItem if needed
      fetchDuplicate();
    };

    // Register the event listener
    window.addEventListener("duplicateCompleted", handleDuplicateCompleted);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener(
        "duplicateCompleted",
        handleDuplicateCompleted
      );
    };
  }, [fetchDuplicate]);

  /**
   * fetchAllowedLevels Function
   *
   * Fetches the allowed permission levels from the server.
   * Displays the processing modal during the fetch.
   * Sets the form modal to be ready upon successful fetch.
   */
  const fetchAllowedLevels = async () => {
    // Show processing modal
    setProcessing(true);
    try {
      // Determine the type based on the duplicate item's type
      const isDocument = duplicate?.type === "file";

      // Prepare data for the permission check API
      const data = {
        parent: parent || "root",
        action: "paste",
        type: isDocument ? "file" : "directory",
      };

      // Make API call to fetch allowed permission levels
      const response = await runData(data, `/api/directories/permissions/`);

      // Handle successful response
      if (response.status === 200 && response.data?.code === 200) {
        setAllowedLevels(response.data.allowedLevels || []);
        // Show confirmation modal
        setShowConfirmModal(true);
      } else {
        await localData("remove", "duplicate");
        setDuplicate(null); // Clear the duplicate after pasting
        // Handle error responses
        setReport({
          show: true,
          message:
            response.data?.message ||
            "An error occurred while fetching allowed permission levels.",
          type: "error",
        });
        setAllowedLevels([]);
      }
    } catch (err) {
      await localData("remove", "duplicate");
      setDuplicate(null); // Clear the duplicate after pasting
      // Handle unexpected errors
      setReport({
        show: true,
        message:
          err.message ||
          "An unexpected error occurred while fetching allowed permission levels.",
        type: "error",
      });
      setAllowedLevels([]);
    } finally {
      // Hide processing modal
      setProcessing(false);
    }
  };

  /**
   * Handler for the Paste Here button click.
   * Initiates a fetch for allowed permission levels before showing the confirmation modal.
   */
  const handlePasteClick = async () => {
    if (!duplicate) {
      setReport({
        show: true,
        message: "No item available to paste.",
        type: "warning",
      });
      return;
    }

    // Initiate fetching allowed permission levels
    await fetchAllowedLevels();
  };

  /**
   * Executes the paste action after user confirmation.
   */
  const handleConfirmPaste = async () => {
    if (!accessType) {
      setReport({
        show: true,
        message: "Please select an access type before proceeding.",
        type: "error",
      });
      return;
    }

    setShowConfirmModal(false);
    setProcessing(true);
    try {
      const data = {
        duplicate_reference: duplicate.reference, // Adjust based on your API requirements
        target_directory: parent, // Assuming 'parent' is the target directory
        access_level: accessType.toLowerCase(), // Convert to lowercase if needed
        duplicate_type: duplicate.type, // Adding duplicate_type as 'file' or 'folder'
      };

      const response = await runData(data, `/api/directories/paste/`);

      if (response.status === 200 && response.data?.code === 200) {
        if (response.data?.resource_type === "folder") {
          // Create a sanitized new directory object
          const newDirectory = {
            title: DOMPurify.sanitize(response.data.title),
            reference: response.data.reference,
            resourceType: response.data.access_type, // Corrected to use response data
            type: "folder",
            parent_reference: response.data.parent_reference,
            shopping: "no"
          };

          // Add the new directory to the parent directory
          addItemToDirectory(parent, newDirectory, "directories");
          setReport({
            show: true,
            message: "Directory pasted successfully!",
            type: "success",
          });
        } else if (response.data?.resource_type === "file") {
          // Create a sanitized new file object
          const newDocument = {
            title: DOMPurify.sanitize(response.data.title),
            reference: response.data.reference,
            resourceType: response.data.access_type, // Corrected to use response data
            type: "file",
            parent_reference: response.data.parent_reference,
          };

          // Add the new file to the parent directory
          addItemToDirectory(parent, newDocument, "documents");
          setReport({
            show: true,
            message: "File pasted successfully!",
            type: "success",
          });
        } else {
          setReport({
            show: true,
            message: "Item might have pasted successfully!",
            type: "success",
          });
        }
        await localData("remove", "duplicate");
        setDuplicate(null); // Clear the duplicate after pasting
      } else {
        setReport({
          show: true,
          message:
            response.data?.message ||
            "Failed to paste the item. Please try again.",
          type: "error",
        });
      }
    } catch (err) {
      console.error("Error during paste operation:", err);
      setReport({
        show: true,
        message:
          err.response?.data?.message ||
          err.message ||
          "An error occurred during the paste operation.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  /**
   * Closes the confirmation modal without performing the paste.
   */
  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
    setAccessType("");
  };

  /**
   * Updates the selected access type and ensures it's valid.
   * @param {Object} e - The event object.
   */
  const handleAccessTypeChange = (e) => {
    setAccessType(e.target.value);
  };

  /**
   * Handler for keydown events to listen for Ctrl/Cmd + V.
   */
  const handleKeyDown = (event) => {
    const isCtrlOrCmd = event.ctrlKey || event.metaKey;
    const isVKey = event.key === "v" || event.key === "V";

    // Check if Ctrl/Cmd + V is pressed
    if (isCtrlOrCmd && isVKey) {
      // Prevent default paste behavior
      event.preventDefault();

      // Ensure that the user is not focused on an input, textarea, or contenteditable element
      const activeElement = document.activeElement;
      const isTyping =
        activeElement &&
        (activeElement.tagName === "INPUT" ||
          activeElement.tagName === "TEXTAREA" ||
          activeElement.isContentEditable);

      if (isTyping) {
        return; // Do not trigger paste if typing
      }

      // Trigger paste
      handlePasteClick();
    }
  };

  // Add event listener for keydown events
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [duplicate, parent, handlePasteClick]); // Re-run if duplicate, parent, or handlePasteClick changes

  return (
    <>
      {duplicate && (
        <Button
          variant="outline-primary"
          className={`d-flex align-items-center justify-content-center mb-3 ${
            buttonProps?.className || ""
          }`}
          onClick={handlePasteClick}
          size="lg"
          {...buttonProps}
        >
          <FaPaste style={{ marginRight: "0.5rem" }} /> Paste
        </Button>
      )}

      {/* Confirmation Modal for Pasting */}
      <Modal show={showConfirmModal} onHide={handleCloseConfirmModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Paste Here</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {duplicate ? (
            <div>
              <p className="mb-4">
                The following {duplicate.type === "folder" ? "folder" : "file"}{" "}
                will be pasted:
              </p>
              <p>
                <strong>
                  {duplicate.type === "folder" ? (
                    <FaFolder color="#bbc" />
                  ) : (
                    <FaFileAlt color="#bbc" />
                  )}{" "}
                  {duplicate.title}
                </strong>
              </p>

              {/* Access Type Dropdown */}
              <Form.Group className="mb-3">
                <Form.Label>Access Type</Form.Label>
                <Form.Control
                  as="select"
                  value={accessType}
                  onChange={handleAccessTypeChange}
                  required
                >
                  <option value="">Select...</option>
                  {allowedLevels.includes("global") && (
                    <option value="Global">Global</option>
                  )}
                  {allowedLevels.includes("company") && (
                    <option value="Company">Company</option>
                  )}
                  {allowedLevels.includes("personal") && (
                    <option value="Personal">Personal</option>
                  )}
                </Form.Control>
              </Form.Group>
            </div>
          ) : (
            <p>No item found to paste.</p>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" onClick={handleCloseConfirmModal}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleConfirmPaste}
            disabled={!accessType}
          >
            Confirm Paste
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

PasteModal.propTypes = {
  parent: PropTypes.string.isRequired,
  buttonProps: PropTypes.object, // Allows passing additional props to the button for styling
};

export default PasteModal;
