/**
 * @file GetStarted.jsx
 * @description Handles user authentication flow (account check, login, account creation, OTP) for Care Quality Support.
 */

import React, { useState, useEffect, useRef } from "react";
import { localData, runData } from "../context/processor";
import { setSession } from "../utils/auth";
import { useProcessing } from "../context/ProcessingModal";
import { useReport } from "../context/ReportModal";
import Logo from "../assets/logo.png"; // Placeholder logo image
import BgImg from "../assets/bg.jpg"; // Background image
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Helmet } from "react-helmet";
import { setUserProfile } from "../account/profileData";

const GetStarted = () => {
  const { setProcessing } = useProcessing();
  const { setReport } = useReport();

  // State for user credentials
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hasAccount, setHasAccount] = useState(null);
  const [userName, setUserName] = useState("");

  // State for “create account” modal
  const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);

  // State for OTP flow
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const otpRefs = useRef([]);
  const otpKeys = [0, 1, 2, 3, 4, 5];
  const [otpRequested, setOtpRequested] = useState(false);
  const [lastOtpRequestTime, setLastOtpRequestTime] = useState(null);

  const [accountFormPage, setAccountFormPage] = useState(1);

  const [accountFormData, setAccountFormData] = useState({
    companyName: "",
    companyEmail: "",
    serviceOffered: "",
    useCompanyEmailForLogin: true,
    userEmail: "",
    phone: "",
    firstName: "",
    lastName: "",
  });

  /**
   * @function handleOtpChange
   * @description Updates the OTP array as the user types. Moves focus to the next field automatically.
   * @param {Object} e The input change event
   * @param {number} index Index of the OTP digit being modified
   */
  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    // Allow only digits
    if (!/^\d$/.test(value) && value !== "") return;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move to the next input field if a digit is entered
    if (value && index < 5) {
      otpRefs.current[index + 1].focus();
    }
  };

  /**
   * @function handleOtpKeyDown
   * @description Handles backspace and arrow key navigation in OTP fields.
   * @param {Object} e The keydown event
   * @param {number} index Index of the current OTP digit
   */
  const handleOtpKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (!otp[index] && index > 0) {
        otpRefs.current[index - 1].focus();
      }
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
    } else if (e.key === "ArrowLeft" && index > 0) {
      otpRefs.current[index - 1].focus();
    } else if (e.key === "ArrowRight" && index < 5) {
      otpRefs.current[index + 1].focus();
    }
  };

  /**
   * @function handleOtpPaste
   * @description Allows the user to paste a 6-digit OTP directly.
   * @param {Object} e The paste event
   */
  const handleOtpPaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("Text").slice(0, 6);
    // Allow only digits
    if (!/^\d+$/.test(pastedData)) return;
    const newOtp = [...otp];
    for (let i = 0; i < pastedData.length; i++) {
      newOtp[i] = pastedData[i];
      if (otpRefs.current[i]) {
        otpRefs.current[i].value = pastedData[i];
      }
    }
    setOtp(newOtp);
    // Focus next empty input or the last one
    const nextIndex = pastedData.length >= 6 ? 5 : pastedData.length;
    if (otpRefs.current[nextIndex]) otpRefs.current[nextIndex].focus();
  };

  // Attach the paste event handler to the first OTP input
  useEffect(() => {
    if (otpRefs.current[0]) {
      otpRefs.current[0].addEventListener("paste", handleOtpPaste);
    }
    return () => {
      if (otpRefs.current[0]) {
        otpRefs.current[0].removeEventListener("paste", handleOtpPaste);
      }
    };
  }, []);

  /**
   * @function handleEmailCheck
   * @description Checks if the user’s email has an existing account.
   * @param {Object} event The form submission event
   */
  const handleEmailCheck = async (event) => {
    event.preventDefault();
    setProcessing(true);
    try {
      const data = { email: email.trim() };
      const response = await runData(data, "/web/get-started/check-email/");

      if (response.status === 200) {
        if (response.data.account === 1) {
          setHasAccount(true);
          setUserName(response.data.name);
        } else {
          setHasAccount(false);
          setShowCreateAccountModal(true);
        }
      } else {
        setReport({
          show: true,
          message:
            response.data.message || "An error occurred during email check.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message:
          err.message || "An unexpected error occurred during email check.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  /**
   * @function handleLoginSubmit
   * @description Submits the user’s email and password for authentication.
   * @param {Object} event The form submission event
   */
  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);
    try {
      const data = {
        email: email.trim(),
        password,
      };
      const response = await runData(data, "/web/get-started/pin-login/");

      if (response.status === 200) {
        const { user } = response.data;
        // Check GDPR status in IndexedDB
        const gdprStatus = await localData("get", "gdpr");

        if (gdprStatus === "accept") {
          // Save user profile locally
          await setUserProfile(user);
          // Set session if GDPR is accepted
          setSession(true);

          setReport({
            show: true,
            message: "You have been logged in successfully using password!",
            type: "success",
          });
        } else {
          // Remove GDPR and session if user has not accepted
          await localData("remove", "gdpr");
          localStorage.removeItem("session");
          setReport({
            show: true,
            message:
              "Your request has been accepted. However, you must accept our GDPR compliance to proceed.",
            type: "error",
          });
        }
      } else {
        setReport({
          show: true,
          message: response.data.message || "An error occurred during login.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message: err.message || "An unexpected error occurred during login.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  /**
   * @function handleRequestOtp
   * @description Requests an OTP for the user’s email. Enforces a 2-minute cooldown.
   */
  const handleRequestOtp = async () => {
    setShowOtpModal(false);
    const TWO_MINUTES = 2 * 60 * 1000;
    const now = Date.now();

    // Enforce 2-minute wait between OTP requests
    if (lastOtpRequestTime && now - lastOtpRequestTime < TWO_MINUTES) {
      const remainingTime = Math.ceil(
        (TWO_MINUTES - (now - lastOtpRequestTime)) / 60000
      );
      setReport({
        show: true,
        message: `Please wait ${remainingTime} minute(s) before requesting a new OTP.`,
        type: "error",
      });
      return;
    }

    setProcessing(true);
    try {
      const data = { email: email.trim() };
      const response = await runData(data, "/web/get-started/request-otp/");

      if (response.status === 200) {
        setOtpRequested(true);
        setLastOtpRequestTime(now);
        setShowOtpModal(true);
      } else {
        setReport({
          show: true,
          message:
            response.data.message || "An error occurred while requesting OTP.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message:
          err.message || "An unexpected error occurred while requesting OTP.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  /**
   * @function handleOtpSubmit
   * @description Submits the OTP for login authentication.
   * @param {Object} event The form submission event
   */
  const handleOtpSubmit = async (event) => {
    event.preventDefault();
    setShowOtpModal(false);
    setProcessing(true);
    try {
      const data = {
        email: email.trim(),
        otp: otp.join("").trim(),
      };
      const response = await runData(data, "/web/get-started/otp-login/");

      if (response.status === 200) {
        const { user } = response.data;
        // Check GDPR status
        const gdprStatus = await localData("get", "gdpr");

        if (gdprStatus === "accept") {
          // Store user profile
          user.last_refresh = new Date().toISOString();
          await setUserProfile(user);
          // Set session
          setSession(true);

          setReport({
            show: true,
            message: "You have been logged in successfully using password!",
            type: "success",
          });
        } else {
          // Remove GDPR and session if not accepted
          await localData("remove", "gdpr");
          localStorage.removeItem("session");
          setReport({
            show: true,
            message:
              "Your request has been accepted, but you must accept GDPR to continue.",
            type: "error",
          });
        }
      } else {
        setReport({
          show: true,
          message: response.data.message || "Invalid OTP. Please try again.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message:
          err.message || "An unexpected error occurred during OTP login.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  /**
   * @function handleAccountCreationSubmit
   * @description Submits the form data to create a new account.
   * @param {Object} event The form submission event
   */
  const handleAccountCreationSubmit = async (event) => {
    event.preventDefault();
    setShowCreateAccountModal(false);
    setProcessing(true);
    try {
      const response = await runData(
        accountFormData,
        "/web/get-started/create-account/"
      );

      if (response.status === 200) {
        setReport({
          show: true,
          message: response.data.message,
          type: "success",
        });
        setHasAccount(null);
      } else {
        setReport({
          show: true,
          message:
            response.data.message ||
            "An error occurred during account creation.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message:
          err.message ||
          "An unexpected error occurred during account creation.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  /**
   * @function getHeaderText
   * @description Determines the header text based on the user’s account status.
   * @returns {string} The header text to display
   */
  const getHeaderText = () => {
    if (hasAccount === null) {
      return "Sign in or create an account";
    } else if (hasAccount) {
      return `Welcome back, ${userName}`;
    } else {
      return "Create an Account";
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Get Started - Care Quality Support</title>
      </Helmet>

      <div className="vh-100 d-flex flex-column align-items-center justify-content-center primary-bg">
        <div className="row w-100 h-100">
          {/* Left Section: Background/Branding */}
          <div
            className="col-md-6 d-none d-md-flex flex-column justify-content-center align-items-center text-white"
            style={{
              backgroundImage: `url(${BgImg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.6)",
              }}
            ></div>
            <h1
              className="text-center fw-bold mb-4"
              style={{
                textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)",
                zIndex: 1,
                fontSize: "2.5rem",
                letterSpacing: "1.2px",
              }}
            >
              Welcome to Care Quality Support
            </h1>
            <h3
              className="text-center fw-bold mt-4"
              style={{
                textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)",
                zIndex: 1,
                letterSpacing: "1.2px",
              }}
            >
              <a
                href="tel:02080642464"
                className="text-primary"
                style={{ textDecoration: "none" }}
              >
                Call 020 8064 2464 for help!
              </a>
            </h3>
            <img
              src={Logo}
              alt="Care Quality Support"
              className="position-absolute bottom-0 mb-4 bg-light"
              style={{
                width: "250px",
                zIndex: 1,
                borderRadius: "10px",
                padding: "20px",
              }}
            />
          </div>

          {/* Right Section: Form/Login */}
          {/* We use a column flex layout here so the footer is naturally pushed below */}
          <div
            className="col-md-6 d-flex flex-column p-4"
            style={{ minHeight: "100vh" }}
          >
            <div
              className="d-flex align-items-center justify-content-center flex-grow-1"
              style={{ width: "100%" }}
            >
              <div
                className="login-card card p-4 shadow"
                style={{
                  borderRadius: "12px",
                  backgroundColor: "#ffffff",
                  maxWidth: "450px",
                  width: "100%",
                  boxShadow: "0px 4px 10px rgba(61, 138, 127, 0.2)",
                }}
              >
                <h4
                  className="mb-4 text-center"
                  style={{
                    fontWeight: 600,
                    color: "#333",
                    fontSize: "2rem",
                    textTransform: "uppercase",
                    letterSpacing: "1px",
                  }}
                >
                  {getHeaderText()}
                </h4>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault(); // Always prevent default first
                    if (hasAccount === null) {
                      // Execute email check
                      handleEmailCheck(e);
                    } else if (hasAccount) {
                      // Proceed to login if account exists
                      handleLoginSubmit(e);
                    } else {
                      // Open the account creation modal if no account
                      setShowCreateAccountModal(true);
                    }
                  }}
                  style={{ marginTop: "10px" }}
                >
                  <Form.Group className="mb-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <Form.Label style={{ fontSize: "1rem", color: "#666" }}>
                        {hasAccount
                          ? "Email"
                          : "What's your email address?"}
                      </Form.Label>
                      {hasAccount && (
                        <Button
                          variant="link"
                          onClick={() => {
                            setHasAccount(null);
                            setEmail("");
                            setPassword("");
                          }}
                          style={{
                            color: "#007bff",
                            textDecoration: "underline",
                            fontSize: "0.9rem",
                          }}
                        >
                          Not You, {userName}?
                        </Button>
                      )}
                    </div>
                    <Form.Control
                      type="email"
                      placeholder="Enter email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      disabled={hasAccount !== null}
                      style={{
                        padding: "15px",
                        fontSize: "1rem",
                        borderRadius: "8px",
                        border: "1px solid #ccc",
                        marginTop: "8px",
                      }}
                    />
                  </Form.Group>

                  {hasAccount && (
                    <Form.Group className="mb-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <Form.Label
                          style={{ fontSize: "1rem", color: "#666" }}
                        >
                          Password
                        </Form.Label>
                        <Button
                          variant="link"
                          onClick={() => setShowOtpModal(true)}
                          style={{
                            color: "#007bff",
                            textDecoration: "underline",
                            fontSize: "0.9rem",
                          }}
                        >
                          Forgot password?
                        </Button>
                      </div>
                      <Form.Control
                        type="password"
                        placeholder="Enter password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        style={{
                          padding: "15px",
                          fontSize: "1rem",
                          borderRadius: "8px",
                          border: "1px solid #ccc",
                          marginTop: "8px",
                        }}
                      />
                    </Form.Group>
                  )}

                  <div className="d-flex flex-column mt-3">
                    {hasAccount === null && (
                      <Button type="submit" className="btn btn-primary w-100">
                        Continue
                      </Button>
                    )}

                    {hasAccount === true && (
                      <Button type="submit" className="btn btn-primary w-100">
                        Login
                      </Button>
                    )}

                    {hasAccount === false && (
                      <Button
                        type="button"
                        className="btn btn-primary w-100"
                        onClick={() => setShowCreateAccountModal(true)}
                      >
                        Create an Account
                      </Button>
                    )}
                  </div>
                </Form>

                <div
                  className="divider my-4 text-center position-relative"
                  style={{ margin: "30px 0" }}
                >
                  <span
                    className="text-muted"
                    style={{
                      backgroundColor: "#ffffff",
                      padding: "0 10px",
                      position: "relative",
                    }}
                  >
                    Important Information
                  </span>
                  <hr
                    style={{
                      borderTop: "1px solid #ddd",
                      position: "absolute",
                      top: "50%",
                      width: "100%",
                      left: 0,
                      margin: 0,
                      zIndex: -1,
                    }}
                  />
                </div>

                <div>
                  <p
                    style={{
                      fontSize: "0.9rem",
                      color: "#555",
                      lineHeight: 1.6,
                      textAlign: "justify",
                    }}
                  >
                    Welcome to the Care Quality Support document management
                    system for health and social care policies and procedures.
                    Whether you are a new or existing user, please enter your
                    email address above to begin.
                  </p>
                </div>

                {/* Create Account Modal */}
                <Modal
                  show={showCreateAccountModal}
                  onHide={() => setShowCreateAccountModal(false)}
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Create an Account</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p className="text-primary text-center">
                      We couldn't find an account associated with your email
                      address. Please create an account to continue.
                    </p>
                    {accountFormPage === 1 && (
                      <Form>
                        <Form.Group className="mb-4">
                          <Form.Label style={{ fontWeight: "bold" }}>
                            Company Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter company name"
                            value={accountFormData.companyName}
                            onChange={(e) =>
                              setAccountFormData({
                                ...accountFormData,
                                companyName: e.target.value,
                              })
                            }
                            required
                            style={{
                              borderRadius: "8px",
                              padding: "10px",
                              boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="mb-4">
                          <Form.Label style={{ fontWeight: "bold" }}>
                            Company Services
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter services offered"
                            value={accountFormData.serviceOffered}
                            onChange={(e) =>
                              setAccountFormData({
                                ...accountFormData,
                                serviceOffered: e.target.value,
                              })
                            }
                            required
                            style={{
                              borderRadius: "8px",
                              padding: "10px",
                              boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="mb-4">
                          <Form.Label style={{ fontWeight: "bold" }}>
                            Company Email
                          </Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter company email"
                            value={accountFormData.companyEmail}
                            onChange={(e) =>
                              setAccountFormData({
                                ...accountFormData,
                                companyEmail: e.target.value,
                              })
                            }
                            required
                            style={{
                              borderRadius: "8px",
                              padding: "10px",
                              boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="mb-4">
                          <Form.Check
                            type="checkbox"
                            label="Use company email for login?"
                            checked={accountFormData.useCompanyEmailForLogin}
                            onChange={(e) =>
                              setAccountFormData({
                                ...accountFormData,
                                useCompanyEmailForLogin: e.target.checked,
                              })
                            }
                            style={{ fontWeight: "bold" }}
                          />
                        </Form.Group>
                      </Form>
                    )}
                    {accountFormPage === 2 && (
                      <Form>
                        {!accountFormData.useCompanyEmailForLogin && (
                          <Form.Group className="mb-4">
                            <Form.Label style={{ fontWeight: "bold" }}>
                              User Email
                            </Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Enter user email"
                              value={accountFormData.userEmail}
                              onChange={(e) =>
                                setAccountFormData({
                                  ...accountFormData,
                                  userEmail: e.target.value,
                                })
                              }
                              required
                              style={{
                                borderRadius: "8px",
                                padding: "10px",
                                boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                              }}
                            />
                          </Form.Group>
                        )}
                        <Form.Group className="mb-4">
                          <Form.Label style={{ fontWeight: "bold" }}>
                            First Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter first name"
                            value={accountFormData.firstName}
                            onChange={(e) =>
                              setAccountFormData({
                                ...accountFormData,
                                firstName: e.target.value,
                              })
                            }
                            required
                            style={{
                              borderRadius: "8px",
                              padding: "10px",
                              boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="mb-4">
                          <Form.Label style={{ fontWeight: "bold" }}>
                            Last Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter last name"
                            value={accountFormData.lastName}
                            onChange={(e) =>
                              setAccountFormData({
                                ...accountFormData,
                                lastName: e.target.value,
                              })
                            }
                            required
                            style={{
                              borderRadius: "8px",
                              padding: "10px",
                              boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="mb-4">
                          <Form.Label style={{ fontWeight: "bold" }}>
                            WhatsApp Number
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter WhatsApp number"
                            value={accountFormData.phone}
                            onChange={(e) =>
                              setAccountFormData({
                                ...accountFormData,
                                phone: e.target.value,
                              })
                            }
                            required
                            style={{
                              borderRadius: "8px",
                              padding: "10px",
                              boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                            }}
                          />
                        </Form.Group>
                      </Form>
                    )}
                  </Modal.Body>
                  <Modal.Footer className="d-flex justify-content-between">
                    {accountFormPage === 1 && (
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setHasAccount(null);
                          setShowCreateAccountModal(false);
                        }}
                        style={{
                          borderRadius: "8px",
                          fontSize: "0.95rem",
                          padding: "10px 20px",
                        }}
                      >
                        I'm Registered
                      </Button>
                    )}
                    {accountFormPage > 1 && (
                      <Button
                        variant="secondary"
                        onClick={() =>
                          setAccountFormPage(accountFormPage - 1)
                        }
                        style={{
                          borderRadius: "8px",
                          fontSize: "0.95rem",
                          padding: "10px 20px",
                        }}
                      >
                        Back
                      </Button>
                    )}
                    {accountFormPage < 2 ? (
                      <Button
                        variant="primary"
                        onClick={() =>
                          setAccountFormPage(accountFormPage + 1)
                        }
                        style={{
                          borderRadius: "8px",
                          fontSize: "0.95rem",
                          padding: "10px 20px",
                        }}
                      >
                        Continue
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        onClick={handleAccountCreationSubmit}
                        style={{
                          borderRadius: "8px",
                          fontSize: "0.95rem",
                          padding: "10px 20px",
                        }}
                      >
                        Submit
                      </Button>
                    )}
                  </Modal.Footer>
                </Modal>

                {/* OTP Modal */}
                <Modal
                  show={showOtpModal}
                  onHide={() => setShowOtpModal(false)}
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Request OTP</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p
                      style={{
                        fontSize: "0.9rem",
                        color: "#666",
                        marginBottom: "20px",
                      }}
                    >
                      {userName}, to log in securely, click "Request OTP." An
                      OTP will be sent to your registered email. Enter it below
                      to proceed. You can change your password in your profile
                      settings after logging in.
                    </p>

                    {otpRequested && (
                      <p className="text-success">
                        An OTP has been sent to your email. Please enter it
                        below to proceed. Note that the OTP is valid for 5
                        minutes, and you may request a new OTP every 2 minutes
                        if needed.
                      </p>
                    )}

                    <Form onSubmit={handleOtpSubmit}>
                      {!otpRequested && (
                        <div className="text-center">
                          <Button
                            variant="primary"
                            onClick={handleRequestOtp}
                            className="w-100"
                            style={{
                              fontSize: "1rem",
                              padding: "12px",
                              borderRadius: "8px",
                            }}
                          >
                            Request OTP
                          </Button>
                        </div>
                      )}

                      {otpRequested && (
                        <>
                          <Form.Group className="mt-4 mb-5">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginBottom: "1rem",
                              }}
                            >
                              <Form.Label
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "0.95rem",
                                }}
                              >
                                Enter OTP
                              </Form.Label>
                              <Button
                                style={{
                                  cursor: "pointer",
                                  fontSize: "0.85rem",
                                }}
                                onClick={() => {
                                  setOtpRequested(false);
                                }}
                              >
                                Request new OTP
                              </Button>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                gap: "10px",
                                marginTop: "10px",
                              }}
                            >
                              {otpKeys.map((key, index) => (
                                <input
                                  key={`otp-input-${key}`}
                                  type="text"
                                  maxLength="1"
                                  ref={(ref) => (otpRefs.current[index] = ref)}
                                  value={otp[index] || ""}
                                  onChange={(e) =>
                                    handleOtpChange(e, index)
                                  }
                                  onKeyDown={(e) =>
                                    handleOtpKeyDown(e, index)
                                  }
                                  style={{
                                    width: "40px",
                                    height: "50px",
                                    textAlign: "center",
                                    fontSize: "1.5rem",
                                    borderRadius: "8px",
                                    border: "1px solid #ccc",
                                    outline: "none",
                                    transition: "border-color 0.3s ease",
                                  }}
                                />
                              ))}
                            </div>
                          </Form.Group>
                          <div className="d-flex justify-content-between">
                            <Button
                              variant="secondary"
                              onClick={() => setShowOtpModal(false)}
                              style={{
                                borderRadius: "8px",
                                fontSize: "0.95rem",
                                padding: "10px 20px",
                                marginRight: "10px",
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="submit"
                              className="btn btn-primary"
                              style={{
                                fontSize: "1rem",
                                padding: "10px 20px",
                                borderRadius: "8px",
                              }}
                            >
                              Login with OTP
                            </Button>
                          </div>
                        </>
                      )}
                    </Form>
                  </Modal.Body>
                </Modal>
              </div>
            </div>

            {/* 
              FIX: This copyright section is now placed 
              in the natural flow (no absolute positioning).
            */}
            <div
              style={{
                fontSize: "0.9rem",
                color: "#666",
                textAlign: "center",
                marginTop: "auto",
              }}
            >
              &copy; {new Date().getFullYear()} Care Quality Support – A GMT
              Networks Ltd Company (Registered in England & Wales: 14549850)
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GetStarted;
