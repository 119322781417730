// src/index.js

import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "./context/ThemeContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/main.scss";
import "./styles/main.css";
import "animate.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import App from "./config/App";
import { ProcessingProvider } from "./context/ProcessingModal";
import { ReportProvider } from "./context/ReportModal";
import { UserProvider } from "./context/userContext";

// Get the root element from the HTML where the React app will be mounted.
const root = document.getElementById("root");

if (root) {
  // Create a root for the React application.
  const rootElement = ReactDOM.createRoot(root);

  // Render the main App component wrapped with the ThemeProvider context.
  rootElement.render(
    <UserProvider>
      <ThemeProvider>
        <ProcessingProvider>
          <ReportProvider>
            <App />
          </ReportProvider>
        </ProcessingProvider>
      </ThemeProvider>
    </UserProvider>
  );
} else {
  console.error(
    "Root element not found. Make sure the HTML file has an element with the id 'root'."
  );
}
