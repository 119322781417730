import React from "react";
import PropTypes from "prop-types";
import { Row, Card } from "react-bootstrap";
import { FaExclamationCircle } from "react-icons/fa";

/**
 * Renders the ownership information for a given resource item.
 *
 * @param {object} props - The props for the component.
 * @param {object} props.item - The resource item object.
 * @param {number} props.slot - The user's current slot.
 * @param {Function} props.handleAction - The function to handle user actions.
 * @param {string} props.typeName - The type name of the resource.
 *
 * @returns {JSX.Element} The JSX representing the ownership UI.
 */
const OwnershipRenderer = ({ item, slot, handleAction, typeName }) => {
  const isGlobalResource = item.resourceType === "global";
  const isNotSubscribed = item?.shopping === "yes";

  const isSlot7or8 = slot === 7 || slot === 8;
  const isSlot9or10 = slot === 9 || slot === 10;

  let globalResourceContent;

  if (isSlot7or8) {
    if (isNotSubscribed) {
      globalResourceContent = (
        <Row>
          <FaExclamationCircle className="mb-5 text-warning" size={150} />
          <Card.Text className="text-mute mb-4">
            You do not have access to this {typeName}. To obtain access, please
            buy it now for £{item.price}.
          </Card.Text>
        </Row>
      );
    } else {
      globalResourceContent = (
        <Card.Text
          className="text-mute text-success"
          onClick={() => handleAction("duplicate")}
          title={`You can click here to copy this ${typeName}!`}
          style={{ cursor: "copy" }}
        >
          You have access to this {typeName}.
        </Card.Text>
      );
    }
  } else if (isSlot9or10) {
    globalResourceContent = (
      <Card.Text className="text-mute text-danger">
        You do not have access to this {typeName}.
      </Card.Text>
    );
  } else {
    globalResourceContent = (
      <Card.Text
        className="text-mute text-success"
        onClick={() => handleAction("duplicate")}
        title={`You can click here to copy this ${typeName}!`}
        style={{ cursor: "copy" }}
      >
        You have access to this {typeName}.
      </Card.Text>
    );
  }

  return (
    <Row className="d-flex justify-content-center align-items-center h-100 text-center">
      <Card className="border-0">
        <Card.Body>
          {isGlobalResource ? (
            <>{globalResourceContent}</>
          ) : (
            <Card.Text
              className="text-mute text-success"
              onClick={() => handleAction("duplicate")}
              title={`You can click here to copy this ${typeName}!`}
              style={{ cursor: "copy" }}
            >
              You have access to this {typeName}.
            </Card.Text>
          )}
        </Card.Body>
      </Card>
    </Row>
  );
};

// PropTypes validation
OwnershipRenderer.propTypes = {
  item: PropTypes.shape({
    resourceType: PropTypes.string.isRequired,
    shopping: PropTypes.string,
    price: PropTypes.string,
  }).isRequired,
  slot: PropTypes.number.isRequired,
  handleAction: PropTypes.func.isRequired,
  typeName: PropTypes.string.isRequired,
};

export default OwnershipRenderer;
