/**
 * @function performApiAction
 * @description Performs the given API action (rename, delete, copy, update_description).
 * @param {Object} item - The resource item.
 * @param {String} action - The action to perform.
 * @param {Object} payload - Additional action parameters.
 * @param {Function} runData - Function to execute API calls.
 * @param {Function} setProcessing - Function to show/hide processing indicator.
 * @param {Function} setReport - Function to show report messages.
 * @param {String} endpoint - The endpoint to call.
 * @returns {Promise<Object|Boolean>} The response data if successful, false otherwise.
 */
export async function performApiAction(
  item,
  action,
  payload,
  runData,
  setProcessing,
  setReport,
  endpoint
) {
  setProcessing(true);
  try {
    const resourceType = item.type === "folder" ? "folder" : "file";
    const data = {
      resource_reference: item.reference,
      resource_type: resourceType,
      action: action,
      ...payload,
    };

    const response = await runData(data, endpoint);
    if (
      response.status === 200 &&
      response.data?.code === 200 &&
      response.data.message
    ) {
      return response.data.message;
    } else {
      setReport({
        show: true,
        message: response.data?.message || `Failed to ${action} the item.`,
        type: "error",
      });
      return false;
    }
  } catch (err) {
    setReport({
      show: true,
      message: err.message || `Unexpected error during ${action}.`,
      type: "error",
    });
    return false;
  } finally {
    setProcessing(false);
  }
}
