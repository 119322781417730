import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Nav, Spinner, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useReport } from "../context/ReportModal";
import { logout } from "../utils/auth";
import { useProcessing } from "../context/ProcessingModal";
import { useUser } from "../hooks/useUser";
import LazyImage from "../utils/LazyImage";
import logo from "../assets/logo.png";
import userImage from "../assets/user-avatar.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt,
  faMoon,
  faSun,
  faUserCog,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { List, LifePreserver } from "react-bootstrap-icons";
import { ThemeContext } from "../context/ThemeContext";
import { runData } from "../context/processor";
import SearchModal from "./Search";
import { FaSearch } from "react-icons/fa";

/**
 * Header component that includes logo, navigation, search input, theme toggle, and user dropdown.
 * @param {Object} props
 * @param {boolean} props.navClosed - Indicates if navigation is closed.
 * @param {Function} props.toggleNav - Function to toggle navigation.
 * @returns {JSX.Element}
 */
const Header = ({ navClosed, toggleNav }) => {
  const { user } = useUser();
  const [isReady, setIsReady] = useState(false);
  const { setReport } = useReport();
  const { setProcessing } = useProcessing();
  const [modalShow, setModalShow] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [modalQuery, setModalQuery] = useState("");
  const { theme, toggleTheme } = useContext(ThemeContext);

  const handleLogout = async () => {
    setProcessing(true);
    try {
      const data = {};
      const response = await runData(data, "/api/logout/");

      if (response.status === 200) {
        setReport({
          show: true,
          message:
            "You have successfully logged out. Thank you for visiting, and we look forward to welcoming you back soon.",
          type: "success",
        });
      } else {
        setReport({
          show: true,
          message:
            response.data.message ||
            "An error occurred while processing logout.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message:
          err.message ||
          "An unexpected error occurred while processing logout.",
        type: "error",
      });
    } finally {
      logout();
      setProcessing(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsReady(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  // Modify openSearchModal function:
  const openSearchModal = () => {
    setModalQuery(searchQuery); // Store current header query for modal
    setModalShow(true); // Open the modal
    setSearchQuery(""); // Clear the header input
  };
  if (!isReady) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" size="lg" />
      </div>
    );
  }

  return (
    <header>
      <div className="header-left">
        {/* Logo and Navigation Toggle */}
        <div className="logosec">
          <Link to={`/`}>
            <LazyImage
              src={logo}
              alt="Logo"
              className="ml-2 mr-2"
              style={{ width: navClosed ? "60px" : "130px" }}
            />
          </Link>
          <List
            size={40}
            className={`menuicn ${navClosed ? "menuicn-center" : ""}`}
            onClick={toggleNav}
          />
        </div>
      </div>

      {/* Responsive Search Input with Icon */}
      <div className="search-container">
        <input
          type="text"
          className="form-control search-input shadow-sm"
          placeholder="Search"
          aria-label="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              openSearchModal();
            }
          }}
        />

        <FaSearch
          className="search-icon"
          onClick={() => {
            openSearchModal();
          }}
          aria-label="Open Search Modal"
        />
      </div>

      <div className="head-right">
        <div className="circle"></div>
        <Link to={`/support/`}>
          <LifePreserver size={30} />
        </Link>

        <button
          onClick={toggleTheme}
          className="theme-toggle-button"
          style={{
            background: "none",
            border: "none",
            cursor: "pointer",
            marginRight: "20px",
          }}
          aria-label="Toggle Theme"
        >
          <FontAwesomeIcon
            icon={theme === "light-theme" ? faMoon : faSun}
            size="lg"
          />
        </button>

        <Dropdown as={Nav.Item} align="end" className="ml-2">
          <Dropdown.Toggle
            as={Nav.Link}
            className="pt-1 px-0 d-flex align-items-center"
          >
            <div className="media d-flex align-items-center">
              <LazyImage
                src={user?.avatar || userImage}
                className="user-avatar md-avatar rounded-circle"
                style={{ height: "35px", width: "35px" }}
              />
              <div
                className={`media-body ms-2 align-items-center d-flex ${
                  navClosed ? "hide-name" : ""
                }`}
              >
                <span className="mb-0 font-small fw-bold me-2 user-name-toggle d-none d-md-block">
                  {user?.name || "You"}
                </span>
                <i className="bi bi-caret-down-fill"></i>
              </div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="user-dropdown dropdown-menu-end mt-2">
            <Link to={`/profile/`} className="dropdown-item fw-bold">
              <FontAwesomeIcon icon={faUserCog} className="me-2" /> Profile
            </Link>
            <Link to={`/billing/`} className="dropdown-item fw-bold">
              <FontAwesomeIcon icon={faWallet} className="me-2" /> Billing
            </Link>
            <Dropdown.Divider />
            <Dropdown.Item className="fw-bold" onClick={handleLogout}>
              <FontAwesomeIcon
                icon={faSignOutAlt}
                className="me-2"
                color="red"
              />
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <SearchModal
        show={modalShow}
        handleClose={() => setModalShow(false)}
        initialQuery={modalQuery}
      />
    </header>
  );
};

Header.propTypes = {
  navClosed: PropTypes.bool.isRequired,
  toggleNav: PropTypes.func.isRequired,
};

export default Header;
