import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Container, Row, Col, Modal, Button, Form } from 'react-bootstrap';

const Palette = () => {
  const availableElements = [
    { id: 'header-1', type: 'Header Text' },
    { id: 'label-1', type: 'Label' },
    { id: 'paragraph-1', type: 'Paragraph' },
    { id: 'linebreak-1', type: 'Line Break' },
    { id: 'dropdown-1', type: 'Dropdown' },
    { id: 'tags-1', type: 'Tags' },
    { id: 'checkboxes-1', type: 'Checkboxes' },
    { id: 'multiplechoice-1', type: 'Multiple Choice' },
    { id: 'textinput-1', type: 'Text Input' },
    { id: 'email-1', type: 'Email' },
    { id: 'numberinput-1', type: 'Number Input' },
    { id: 'phonenumber-1', type: 'Phone Number' },
    { id: 'multiline-1', type: 'Multi-line Input' },
    { id: 'twocolumn-1', type: 'Two Column Row' },
    { id: 'threecolumn-1', type: 'Three Columns Row' },
    { id: 'fourcolumn-1', type: 'Four Columns Row' },
    { id: 'image-1', type: 'Image' },
    { id: 'rating-1', type: 'Rating' },
    { id: 'date-1', type: 'Date' },
    { id: 'signature-1', type: 'Signature' },
    { id: 'website-1', type: 'Website' },
    { id: 'fileattachment-1', type: 'File Attachment' },
    { id: 'range-1', type: 'Range' },
    { id: 'camera-1', type: 'Camera' },
    { id: 'fileupload-1', type: 'File Upload' },
  ];

  return (
    <Droppable droppableId="palette" isDropDisabled>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {availableElements.map((item, index) => (
            <Draggable key={item.id} draggableId={item.id} index={index}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={{
                    padding: '8px',
                    marginBottom: '4px',
                    background: '#f8f9fa',
                    border: '1px solid #dee2e6',
                    borderRadius: '4px',
                    cursor: 'grab',
                    ...provided.draggableProps.style,
                  }}
                >
                  {item.type}
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const FormElement = ({ element, onSelect }) => {
  const handleClick = () => {
    onSelect(element);
  };

  switch (element.type) {
    case 'Two Column Row':
      return (
        <Row onClick={handleClick} style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '8px' }}>
          <Col style={{ borderRight: '1px dashed #ccc' }}>Column 1</Col>
          <Col>Column 2</Col>
        </Row>
      );
    case 'Three Columns Row':
      return (
        <Row onClick={handleClick} style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '8px' }}>
          <Col style={{ borderRight: '1px dashed #ccc' }}>Col 1</Col>
          <Col style={{ borderRight: '1px dashed #ccc' }}>Col 2</Col>
          <Col>Col 3</Col>
        </Row>
      );
    case 'Four Columns Row':
      return (
        <Row onClick={handleClick} style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '8px' }}>
          <Col style={{ borderRight: '1px dashed #ccc' }}>Col 1</Col>
          <Col style={{ borderRight: '1px dashed #ccc' }}>Col 2</Col>
          <Col style={{ borderRight: '1px dashed #ccc' }}>Col 3</Col>
          <Col>Col 4</Col>
        </Row>
      );
    default:
      return (
        <div onClick={handleClick} style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '4px', background: '#fff', marginBottom: '8px' }}>
          <strong>{element.type}</strong>
        </div>
      );
  }
};

const SettingsPanel = ({ show, element, handleClose, onSave }) => {
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [newOption, setNewOption] = useState('');

  const handleAddOption = () => {
    if (newOption.trim()) {
      setDropdownOptions([...dropdownOptions, newOption.trim()]);
      setNewOption('');
    }
  };

  const handleRemoveOption = (index) => {
    setDropdownOptions(dropdownOptions.filter((_, i) => i !== index));
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Settings for {element?.type}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formElementText">
            <Form.Label>Label/Text</Form.Label>
            <Form.Control type="text" placeholder={`Enter ${element?.type} text`} />
          </Form.Group>
          {element?.type === 'Dropdown' && (
            <Form.Group controlId="dropdownOptions">
              <Form.Label>Dropdown Options</Form.Label>
              <div>
                {dropdownOptions.map((option, index) => (
                  <div key={index} className="d-flex justify-content-between align-items-center mb-2">
                    <span>{option}</span>
                    <Button variant="danger" size="sm" onClick={() => handleRemoveOption(index)}>
                      Remove
                    </Button>
                  </div>
                ))}
              </div>
              <div className="d-flex">
                <Form.Control
                  type="text"
                  placeholder="Add new option"
                  value={newOption}
                  onChange={(e) => setNewOption(e.target.value)}
                />
                <Button variant="primary" onClick={handleAddOption} className="ms-2">
                  Add
                </Button>
              </div>
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} className="me-auto">
          Cancel
        </Button>
        <Button variant="primary" onClick={onSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const FormBuilder = () => {
  const [elements, setElements] = useState([]);
  const [selectedElement, setSelectedElement] = useState(null);
  const [showSettings, setShowSettings] = useState(false);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    if (result.source.droppableId === 'palette' && result.destination.droppableId === 'formCanvas') {
      const newElement = {
        id: `element-${new Date().getTime()}`,
        type: result.draggableId.split('-')[0],
      };
      setElements((prev) => {
        const updatedElements = [...prev];
        updatedElements.splice(result.destination.index, 0, newElement);
        return updatedElements;
      });
    } else if (
      result.source.droppableId === 'formCanvas' &&
      result.destination.droppableId === 'formCanvas'
    ) {
      const reorderedItems = Array.from(elements);
      const [movedItem] = reorderedItems.splice(result.source.index, 1);
      reorderedItems.splice(result.destination.index, 0, movedItem);
      setElements(reorderedItems);
    }
  };

  const handleSelectElement = (element) => {
    setSelectedElement(element);
    setShowSettings(true);
  };

  const handleCloseSettings = () => {
    setShowSettings(false);
    setSelectedElement(null);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Container fluid>
        <Row>
          <Col md={3}>
            <h5>Toolbox</h5>
            <Palette />
          </Col>
          <Col md={9}>
            <h5>Form Canvas</h5>
            <Droppable droppableId="formCanvas">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    minHeight: '80vh',
                    border: '1px dashed #ccc',
                    padding: '10px',
                    background: '#fafafa',
                  }}
                >
                  {elements.map((element, index) => (
                    <Draggable key={element.id} draggableId={element.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            userSelect: 'none',
                            marginBottom: '8px',
                            ...provided.draggableProps.style,
                          }}
                        >
                          <FormElement element={element} onSelect={handleSelectElement} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Button variant="primary">Save Form</Button>
          </Col>
        </Row>
      </Container>
      <SettingsPanel
        show={showSettings}
        element={selectedElement}
        handleClose={handleCloseSettings}
        onSave={handleCloseSettings}
      />
    </DragDropContext>
  );
};

export default FormBuilder;
