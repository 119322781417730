import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";

/**
 * @component SettingsModal
 * @description Modal for updating the price of an item or toggling its visibility for a company.
 * @param {Object} props - Component properties.
 * @param {Object|null} props.item - The item whose settings are to be updated.
 * @param {boolean} props.show - Visibility state of the modal.
 * @param {Function} props.handleClose - Function to close the modal.
 * @param {Function} props.onConfirm - Function to confirm the settings update.
 * @param {number} props.slot - Numerical identifier used to determine what fields to show.
 * @returns {JSX.Element} Returns a modal that conditionally displays either a price field or a hide/show field.
 */
const SettingsModal = ({ item, show, handleClose, onConfirm, slot }) => {
  // newPrice holds the numeric price value in string form
  const [newPrice, setNewPrice] = useState("");

  // hideSetting is a string: "yes" or "no"
  // "yes" means hidden, "no" means visible
  const [hideSetting, setHideSetting] = useState("no");

  // Check if the item is a global resource and if the slot is 7 or 8
  const isGlobalResourceTypeAndSlot78 =
    item?.resourceType === "global" && (slot === 7 || slot === 8);

  /**
   * @function useEffect - Initializes or resets form fields depending on modal visibility and resource type/slot.
   */
  useEffect(() => {
    if (show) {
      if (isGlobalResourceTypeAndSlot78) {
        // For hide/show settings, initialize to "no" (unhidden)
        setHideSetting("no");
      } else {
        // For price setting, initialize price from the item
        setNewPrice(item?.price ? String(item.price) : "");
      }
    }
  }, [show, item?.price, isGlobalResourceTypeAndSlot78]);

  /**
   * @function useEffect - Resets form values when the modal is hidden.
   */
  useEffect(() => {
    if (!show) {
      setNewPrice("");
      setHideSetting("no");
    }
  }, [show]);

  /**
   * @function onSubmit
   * @description Handles form submission, setting either `hidden` or `price` based on resource type and slot.
   * @param {React.FormEvent} e - The form submit event.
   */
  const onSubmit = (e) => {
    e.preventDefault();

    // If it's a global resource and slot is 7 or 8, save hide/show setting
    if (isGlobalResourceTypeAndSlot78) {
      onConfirm({ hidden: hideSetting }); // Submit "yes" or "no"
      handleClose();
      return;
    }

    // Otherwise, handle the price update
    const priceValue = parseFloat(newPrice);
    if (!isNaN(priceValue) && priceValue >= 0) {
      onConfirm({ price: priceValue }); // Submit numeric price
      handleClose();
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            {item?.type === "folder" ? "Folder" : "File"} Settings
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/* If item is a global resource and slot is 7 or 8, show hide/show field */}
          {isGlobalResourceTypeAndSlot78 ? (
            <Form.Group className="mb-3" controlId="hideShowForm">
              <Form.Label className="mb-4">Visibility for Company</Form.Label>
              <Form.Check
                type="checkbox"
                label="Hide from your company?"
                checked={hideSetting === "yes"}
                onChange={(e) =>
                  setHideSetting(e.target.checked ? "yes" : "no")
                }
              />
              <Form.Text className="text-muted">
                Toggle whether this{" "}
                {item?.type === "folder" ? "folder" : "file"} should be hidden
                from the rest of your company.
              </Form.Text>
            </Form.Group>
          ) : (
            /* Otherwise, show price setting field */
            <Form.Group className="mb-3" controlId="priceForm">
              <Form.Label>Selling Price (£)</Form.Label>
              <InputGroup>
                <InputGroup.Text>£</InputGroup.Text>
                <Form.Control
                  type="number"
                  value={newPrice}
                  onChange={(e) => setNewPrice(e.target.value)}
                  placeholder="Enter new price"
                  required
                  min="0"
                  step="0.01"
                  autoFocus
                />
              </InputGroup>
              <Form.Text className="text-muted">
                Please enter the price in British Pounds. Current price is £
                {item?.price || 0}.
              </Form.Text>
            </Form.Group>
          )}
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={
              isGlobalResourceTypeAndSlot78
                ? false // No numeric validation needed for hide/show
                : newPrice.trim() === "" || isNaN(parseFloat(newPrice))
            }
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

SettingsModal.propTypes = {
  item: PropTypes.shape({
    reference: PropTypes.string.isRequired,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string.isRequired,
    resourceType: PropTypes.string, // for the global check
  }),
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  slot: PropTypes.number.isRequired,
};

export default SettingsModal;
