import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Button, Alert } from "react-bootstrap";

/**
 * DeclarationConfirmation Component
 *
 * @param {Object} props - Component properties
 * @param {boolean} props.shouldShow - Determines if the declaration form should be displayed
 * @param {string} props.docRef - Document reference identifier
 * @param {function} props.runData - Function to execute API calls
 * @param {function} props.setProcessing - Function to toggle processing state
 * @param {function} props.setReport - Function to set report messages
 * @component
 * @example
 * return (
 *   <DeclarationConfirmation
 *      shouldShow={true}
 *      docRef="document123"
 *      runData={someRunDataFunction}
 *      setProcessing={someSetProcessingFunction}
 *      setReport={someSetReportFunction}
 *   />
 * )
 */
const DeclarationConfirmation = ({
  shouldShow,
  docRef,
  runData,
  setProcessing,
  setReport,
}) => {
  /**
   * Handler for the confirm button which can be connected to further logic or API calls.
   */
  const handleConfirm = async () => {
    setProcessing(true);
    try {
      // Data payload for the API request
      const data = { reference: docRef };
      // Make the API call using the provided runData function
      const response = await runData(
        data,
        `/api/directories/documents/confirm/`
      );

      // Check if the response status and data code are successful
      if (response.status === 200 && response.data?.code === 200) {
        // Determine success message from response or use default
        const goodMsg =
          response.data?.message || "Thank you for confirming this!";
        // Report the successful message to the user
        setReport({ show: true, message: goodMsg, type: "success" });
      } else {
        // Determine error message from response or use default
        const errMsg = response.data?.message || "Failed to fetch details.";
        // Report the error to the user
        setReport({ show: true, message: errMsg, type: "error" });
      }
    } catch (err) {
      // Handle unexpected errors during the fetch operation
      const errMsg = err.message || "An unexpected error occurred.";
      setReport({ show: true, message: errMsg, type: "error" });
    } finally {
      // Indicate that processing has finished
      setProcessing(false);
    }
  };

  // If shouldShow is false, do not render the declaration.
  if (!shouldShow) {
    return null;
  }

  return (
    <Container className="my-4">
      <Alert variant="info">
        <Row className="align-items-center">
          <Col>
            <p className="mb-0 h5">
              I hereby confirm that I have read and understood the document
              above.
            </p>
          </Col>
          <Col xs="auto">
            <Button variant="primary" onClick={handleConfirm}>
              Confirm
            </Button>
          </Col>
        </Row>
      </Alert>
    </Container>
  );
};

DeclarationConfirmation.propTypes = {
  /** Determines if the declaration form should be displayed */
  shouldShow: PropTypes.bool.isRequired,
  /** Document reference identifier */
  docRef: PropTypes.string.isRequired,
  /** Function to execute API calls */
  runData: PropTypes.func.isRequired,
  /** Function to toggle processing state */
  setProcessing: PropTypes.func.isRequired,
  /** Function to set report messages */
  setReport: PropTypes.func.isRequired,
};

export default DeclarationConfirmation;
