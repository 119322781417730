// DuplicateModal.jsx
import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";

/**
 * @component DuplicateModal
 * @description Modal for confirming duplication of an item.
 * @param {Object} props
 * @param {Object|null} props.item - The item to duplicate.
 * @param {Boolean} props.show - Visibility state of the modal.
 * @param {Function} props.handleClose - Function to close the modal.
 * @param {Function} props.onConfirm - Function to confirm the duplicate action.
 * @returns {JSX.Element}
 */
const DuplicateModal = ({ item, show, handleClose, onConfirm }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Duplicate</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        You are about to copy the item "{item?.title}". Confirm to proceed.
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => onConfirm("duplicate")}>
          Confirm Copy
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DuplicateModal.propTypes = {
  item: PropTypes.object,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default DuplicateModal;
