import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import Header from "../components/Header";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import { ChevronBarUp } from "react-bootstrap-icons";
import { localData } from "../context/processor";
import { useReport } from "../context/ReportModal";
import useSessionWatch from "../hooks/useSessionWatch"; // Import the custom hook

const Layout = ({ children }) => {
  const location = useLocation();
  const { setReport } = useReport(); // Destructure setReport properly from the useReport hook
  const sessionValid = useSessionWatch(); // Use the custom hook to monitor session validity

  // State to handle the navigation and scroll-to-top visibility
  const [navClosed, setNavClosed] = useState(window.innerWidth < 850);
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  // Fetch initial nav state after mount
  useEffect(() => {
    const fetchNavState = async () => {
      try {
        const savedNavState = await localData("get", "layoutNavClosed");
        if (window.innerWidth >= 850 && savedNavState !== null) {
          setNavClosed(savedNavState);
        }
      } catch (error) {
        console.error("Failed to fetch navigation state:", error);
      }
    };
    fetchNavState();
  }, []);

  // Toggle navigation open/close state
  const toggleNav = useCallback(async () => {
    const newState = !navClosed;
    setNavClosed(newState);

    if (window.innerWidth >= 850) {
      try {
        await localData("save", "layoutNavClosed", newState);
      } catch (error) {
        console.error("Failed to save navigation state:", error);
      }
    }
  }, [navClosed]);

  // Scroll to top whenever location (URL) changes
  useEffect(() => {
    const mainContent = document.querySelector(".main");
    if (mainContent) {
      mainContent.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);

  // Show or hide the scroll-to-top button based on scrolling
  useEffect(() => {
    const mainContent = document.querySelector(".main");

    const handleScroll = () => {
      setShowScrollToTop(mainContent && mainContent.scrollTop > 300);
    };

    if (mainContent) {
      mainContent.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (mainContent) {
        mainContent.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  // Scroll to top function for the button
  const scrollToTop = useCallback(() => {
    const mainContent = document.querySelector(".main");
    if (mainContent) {
      mainContent.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  // Display a report when the loggedOut event is triggered
  useEffect(() => {
    const handleLoggedOutEvent = () => {
      setReport({
        show: true,
        message:
          "Your session has ended. Thank you for visiting, and we look forward to welcoming you back soon.",
        type: "warning",
      });
    };

    window.addEventListener("loggedOut", handleLoggedOutEvent);

    return () => {
      window.removeEventListener("loggedOut", handleLoggedOutEvent);
    };
  }, [setReport]);

  // If the session is not valid or it's still being determined, render nothing or a loading spinner
  if (sessionValid === null) {
    return (
      <div className="loading-container">
        <p>Loading...</p>
      </div>
    );
  }

  // Properly render different parts based on authentication state
  return (
    <React.Fragment>
      {sessionValid ? (
        <>
          <Header navClosed={navClosed} toggleNav={toggleNav} />
          <div className="main-container">
            <Navigation navClosed={navClosed} toggleNav={toggleNav} />
            <div
              className="main"
              style={{
                position: "relative",
                overflowY: "auto",
                height: "calc(100vh - 100px)",
              }}
            >
              <div style={{ minHeight: "75vh" }}>{children}</div>
              <Footer />
            </div>
            {/* Scroll-to-top button */}
            {showScrollToTop && (
              <button
                onClick={scrollToTop}
                style={{
                  position: "fixed",
                  bottom: "50px",
                  right: "50px",
                  padding: "10px",
                  fontSize: "16px",
                  borderRadius: "50%",
                  color: "#fff",
                  backgroundColor: "red",
                  border: "none",
                  cursor: "pointer",
                }}
                aria-label="Scroll to top"
              >
                <ChevronBarUp size={40} />
              </button>
            )}
          </div>
        </>
      ) : (
        // For unauthenticated users, render the children without the authenticated UI components
        <div className="unauthenticated-container">{children}</div>
      )}
    </React.Fragment>
  );
};

// Add PropTypes validation for the 'children' prop
Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
