/**
 * @file GdprNotification.jsx
 * @description Displays a notification bar for GDPR compliance and two modals (Privacy & Cookie Policies).
 *              Uses Bootstrap Buttons and includes final, formatted policies.
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { localData } from "../context/processor";
import Button from "react-bootstrap/Button";
import { v4 as uuidv4 } from "uuid";

/**
 * @function PolicyModal
 * @description A reusable modal component for displaying policy information.
 * @param {Object} props - The props object
 * @param {boolean} props.isOpen - Determines whether the modal is visible
 * @param {Function} props.onClose - Callback function to close the modal
 * @param {Function} [props.onAccept] - Optional callback function to accept the policy
 * @param {string} props.title - Title displayed in the modal header
 * @param {string} props.content - Main text content (policy details) displayed in the modal
 * @returns {JSX.Element|null} The rendered modal or null if not open
 */
const PolicyModal = ({ isOpen, onClose, onAccept, title, content }) => {
  if (!isOpen) return null;

  return (
    <div style={modalStyles.overlay}>
      <div style={modalStyles.content}>
        <h2 style={{ marginBottom: "15px" }}>{title}</h2>
        <div
          style={{
            marginBottom: "20px",
            maxHeight: "50vh",
            overflowY: "auto",
          }}
        >
          {content.split("\n\n").map((para) => (
            <p style={{ lineHeight: 1.6, marginBottom: "1rem" }} key={uuidv4()}>
              {para}
            </p>
          ))}
        </div>
        {/* Modal Buttons: Accept on the left, Close on the far right */}
        <div style={modalStyles.ButtonContainer}>
          {typeof onAccept === "function" && (
            <Button className="btn btn-success" onClick={onAccept}>
              Accept
            </Button>
          )}
          <Button className="btn btn-secondary" onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};

PolicyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

/**
 * @function GdprNotification
 * @description Handles the GDPR notification bar. Prompts users to accept/reject GDPR compliance.
 *              If not previously set, the notification is shown. Also includes modals for Privacy & Cookie Policies.
 * @returns {JSX.Element} The GDPR notification bar with two modals for policy details
 */
const GdprNotification = () => {
  const [showNotification, setShowNotification] = useState(false);

  // State for controlling the policy modals
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
  const [cookieModalOpen, setCookieModalOpen] = useState(false);

  useEffect(() => {
    /**
     * @function checkGdprStatus
     * @description Checks whether the gdpr key is stored in localData. If not, show the notification.
     */
    const checkGdprStatus = async () => {
      const gdprStatus = await localData("get", "gdpr");
      if (!gdprStatus) {
        // Show notification if no gdpr status is set
        setShowNotification(true);
      } else {
        // Hide if accepted or rejected
        setShowNotification(false);
      }
    };

    // Check GDPR status on mount
    checkGdprStatus();

    // Optionally poll every second (based on your requirement)
    const interval = setInterval(checkGdprStatus, 1000);
    return () => clearInterval(interval);
  }, []);

  /**
   * @function handleAcceptGdpr
   * @description Accepts GDPR and saves the status in local storage.
   */
  const handleAcceptGdpr = async () => {
    await localData("save", "gdpr", "accept");
    setShowNotification(false);
  };

  /**
   * @function handleRejectGdpr
   * @description Rejects GDPR and saves the status in local storage.
   */
  const handleRejectGdpr = async () => {
    await localData("save", "gdpr", "reject");
    setShowNotification(false);
  };

  return (
    <>
      {showNotification && (
        <div style={styles.notificationContainer}>
          <div style={styles.notificationContent}>
            <p style={styles.message}>
              We value your privacy and are committed to complying with the
              General Data Protection Regulation (GDPR). Please review our{" "}
              <Button
                className="btn bg-transparent"
                onClick={() => setPrivacyModalOpen(true)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") setPrivacyModalOpen(true);
                }}
              >
                Privacy Policy
              </Button>{" "}
              and{" "}
              <Button
                className="btn bg-transparent"
                onClick={() => setCookieModalOpen(true)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") setCookieModalOpen(true);
                }}
              >
                Cookie Policy
              </Button>
              . Kindly accept our use of GDPR-compliant practices to continue.
            </p>

            <div style={styles.ButtonContainer}>
              <Button
                className="btn btn-primary"
                onClick={handleAcceptGdpr}
                style={styles.acceptButton}
              >
                Accept
              </Button>
              <Button
                className="btn btn-secondary"
                onClick={handleRejectGdpr}
                style={styles.rejectButton}
              >
                Reject
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* Privacy Policy Modal */}
      <PolicyModal
        isOpen={privacyModalOpen}
        onClose={() => setPrivacyModalOpen(false)}
        onAccept={handleAcceptGdpr}
        title="Care Quality Support Privacy Policy"
        content={privacyPolicyText}
      />

      {/* Cookie Policy Modal */}
      <PolicyModal
        isOpen={cookieModalOpen}
        onClose={() => setCookieModalOpen(false)}
        onAccept={handleAcceptGdpr}
        title="Care Quality Support Cookie Policy"
        content={cookiePolicyText}
      />
    </>
  );
};

/**
 * Inline styles for the notification bar and elements
 */
const styles = {
  notificationContainer: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    backgroundColor: "#f9f9f9",
    color: "#333",
    padding: "15px",
    textAlign: "center",
    zIndex: 1000,
    boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
  },
  notificationContent: {
    maxWidth: "800px",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  message: {
    marginBottom: "10px",
    fontSize: "0.95rem",
    lineHeight: 1.6,
  },
  modalLinkText: {
    textDecoration: "none", // Removed underlining
    cursor: "pointer",
    fontWeight: "bold",
    color: "#0d6efd", // Bootstrap primary color for clickable text
    outline: "none",
  },
  ButtonContainer: {
    display: "flex",
    gap: "10px",
  },
  acceptButton: {
    fontWeight: "bold",
  },
  rejectButton: {
    fontWeight: "bold",
  },
};

/**
 * Inline styles for the policy modal overlay and content
 */
const modalStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 2000,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    backgroundColor: "#f8f8f8",
    color: "#333",
    borderRadius: "8px",
    width: "80%",
    maxWidth: "600px",
    padding: "20px",
    boxSizing: "border-box",
    position: "relative",
  },
  ButtonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  },
};

/**
 * @constant privacyPolicyText
 * @type {string}
 * @description Final, structured Privacy Policy text (last updated 01 January 2025).
 */
const privacyPolicyText = `Last Updated: 01 January 2025

1. Introduction

Care Quality Support (“we,” “us,” or “our”) respects your privacy and is committed to protecting your personal information. This Privacy Policy explains how we collect, use, and share your personal data when you interact with our services in accordance with the General Data Protection Regulation (GDPR).

2. Data We Collect

- Personal Information: This may include your name, email address, phone number, and similar details when you register or use our services.

- Usage Data: We collect information on how you navigate our website or platform, such as IP addresses, browser types, and activity logs.

3. How We Use Your Data

- Service Provision: We process your personal data to operate and maintain our core offerings, including domain registration, web hosting, and DNS management.

- Communication: We may send you emails or messages about your account, important updates, and technical notices.

- Marketing (If Applicable): We may send you promotional messages if you consent to receive them. You can opt out at any time.

4. Data Sharing

We may share your data with trusted third-party providers who assist in delivering our services (such as payment processors or hosting partners). These entities are obligated to comply with GDPR and protect your data.

5. Data Retention & Security

We retain personal data only as long as necessary for legitimate business or legal purposes. We implement security measures, including encryption and access restrictions, to safeguard your data against unauthorized access, alteration, or disclosure.

6. Your Rights Under GDPR

You have the right to:

- Access the personal data we hold about you

- Request corrections or deletions

- Object to or restrict our processing of your data

- Port your data to another service

For assistance, please contact us using the details below.

7. Updates to This Policy

We may update this Privacy Policy to reflect changes in our practices or to comply with legal obligations. Changes will be posted on our website.

8. Contact Information

If you have any questions or concerns about this Privacy Policy, please contact us at:

Email:
admin@carequalitysupport.co.uk 

Address:
No 55B Basepoint Business and Innovation Centre, Metcalf Way. Crawley. West Sussex, RH11 7XX

Phone:
020 8064 2464
`;

/**
 * @constant cookiePolicyText
 * @type {string}
 * @description Final, structured Cookie Policy text (last updated 01 January 2025).
 */
const cookiePolicyText = `Last Updated: 01 January 2025

1. Introduction

Care Quality Support (“we,” “us,” or “our”) uses cookies and similar technologies to enhance your experience and ensure our services function properly.

2. What Are Cookies?

Cookies are small data files stored on your device when you visit a website. They help us remember your preferences and improve site performance.

3. Types of Cookies We Use

- Strictly Necessary Cookies: Essential for the operation of our website.

- Performance Cookies: Collect anonymous data on how users interact with our site, helping us improve its efficiency.

- Functionality Cookies: Remember your preferences to customize your experience.

- Targeting/Advertising Cookies: May be used to deliver relevant ads, with your consent.

4. Managing Cookies

You can modify your cookie preferences or disable cookies entirely through your browser settings. However, some features of our services may not function correctly if cookies are disabled.

5. Third-Party Services

We may use trusted third-party services (like analytics providers) that set cookies on our behalf. These third parties are required to comply with GDPR.

6. Policy Updates

We may update this Cookie Policy to reflect changes in our technology or data practices. Updated versions will be posted on our website.

7. Contact Us

For questions about our Cookie Policy or to learn more about how we use cookies, contact us at:

Email:
admin@carequalitysupport.co.uk 

Address:
No 55B Basepoint Business and Innovation Centre, Metcalf Way. Crawley. West Sussex, RH11 7XX

Phone:
020 8064 2464
`;

export default GdprNotification;
