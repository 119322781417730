export default function notifyDirectoryChanged(changeDetails = {}) {
    const event = new CustomEvent("directoryChanged", {
      detail: {
        message: "The directory has been updated",
        ...changeDetails,
        timestamp: Date.now(),
      },
    });
    window.dispatchEvent(event);
  }
  