import React, { createContext, useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { getUserProfile, setUserProfile } from "../account/profileData";
import { runData } from "./processor";
import { logout } from "../utils/auth";
import notifyLogout from "../events/logoutNotice";

// Create a UserContext
export const UserContext = createContext(null);

// Create a provider component
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({});

  useEffect(() => {
    let isMounted = true;

    /**
     * Function to fetch and update the user profile from IndexedDB.
     * If the data is older than 5 minutes, fetch new data from the API and update the local DB.
     * @returns {Promise<void>}
     */
    const fetchUserProfileFromDB = async () => {
      let userProfile = null; // Declare userProfile to make it accessible throughout the function

      try {
        userProfile = await getUserProfile();
        if (isMounted && userProfile) {
          const lastRefresh = new Date(userProfile.last_refresh);
          const currentTime = new Date();
          const minutesDifference = (currentTime - lastRefresh) / (1000 * 60);

          if (minutesDifference > 5) {
            // Fetch from API if more than 5 minutes have passed
            const data = {};
            const response = await runData(data, "/api/profile/");
            if (response.status === 200) {
              const { user } = response.data;
              user.last_refresh = new Date().toISOString();
              await setUserProfile(user);
              setUser(user);
            } else if (response.status === 401) {
              notifyLogout();
              logout();
            } else {
              console.error(
                "Failed to fetch user profile from API. Status:",
                response.status
              );
              // Default to local data and update last refresh to avoid frequent retries
              userProfile.last_refresh = new Date().toISOString();
              await setUserProfile(userProfile);
              setUser(userProfile);
            }
          } else {
            setUser(userProfile);
          }
        }
      } catch (error) {
        console.error("Error fetching user profile from IndexedDB:", error);
        // Default to local data and update last refresh to avoid frequent retries
        if (userProfile) {
          userProfile.last_refresh = new Date().toISOString();
          await setUserProfile(userProfile);
          setUser(userProfile);
        }
      }
    };

    // Initial fetch of user profile
    fetchUserProfileFromDB();

    /**
     * Event listener to handle profile changes.
     * @param {CustomEvent} event - The custom event containing profile change details.
     */
    const handleProfileChange = (event) => {
      console.log(event.detail.message, "at", new Date(event.detail.timestamp));
      fetchUserProfileFromDB();
    };

    // Add event listener for "profileChanged"
    window.addEventListener("profileChanged", handleProfileChange);

    // Cleanup when the component unmounts
    return () => {
      isMounted = false;
      window.removeEventListener("profileChanged", handleProfileChange);
    };
  }, []);

  /**
   * Updates the user profile in the context and saves it in IndexedDB.
   * This function is used for updating the user's information, not for refreshing the profile.
   * @param {object} newUserData - The new user data to update.
   * @returns {Promise<void>}
   */
  const updateUser = async (newUserData) => {
    try {
      const updatedUser = {
        ...user,
        ...newUserData,
      };

      // Save updated user in IndexedDB
      await setUserProfile(updatedUser);
      setUser(updatedUser);
    } catch (error) {
      console.error("Error updating user profile in IndexedDB:", error);
    }
  };

  // Memoize the value provided to avoid re-renders unless dependencies change
  const contextValue = useMemo(
    () => ({
      user,
      updateUser,
    }),
    [user]
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * @typedef {object} UserContextType
 * @property {object} user - The current user profile data.
 * @property {function(object): Promise<void>} updateUser - Function to update the user profile.
 */
