import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Dropdown, Button } from "react-bootstrap";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

/**
 * @component FilterAndPaginationControls
 * @description Handles filtering options, pagination buttons, and items-per-page selection.
 * @param {Object} props
 * @param {String} props.filterType - Current filter type.
 * @param {Function} props.setFilterType - Function to set the filter type.
 * @param {Number} props.itemsPerPage - Current number of items per page.
 * @param {Array} props.itemsPerPageOptions - Available options for items per page.
 * @param {Number} props.totalItems - Total number of items after filtering.
 * @param {Number} props.currentPage - Current active page.
 * @param {Number} props.totalPages - Total number of pages.
 * @param {Function} props.goToFirstPage - Function to navigate to the first page.
 * @param {Function} props.goToPreviousPage - Function to navigate to the previous page.
 * @param {Function} props.goToNextPage - Function to navigate to the next page.
 * @param {Function} props.goToLastPage - Function to navigate to the last page.
 * @param {Function} props.handleItemsPerPageChange - Function to change items per page.
 * @returns {JSX.Element}
 */
const FilterAndPaginationControls = ({
  filterType,
  setFilterType,
  itemsPerPage,
  itemsPerPageOptions,
  totalItems,
  currentPage,
  totalPages,
  goToFirstPage,
  goToPreviousPage,
  goToNextPage,
  goToLastPage,
  handleItemsPerPageChange,
}) => {
  /**
   * @function handleKeyDown
   * @description Handles keydown events for left and right arrow navigation.
   * @param {KeyboardEvent} event - The keyboard event.
   */
  const handleKeyDown = (event) => {
    // Prevent interference when the user is typing in an input, textarea, or editable content.
    const activeElement = document.activeElement;
    const isTyping =
      activeElement &&
      (activeElement.tagName === "INPUT" ||
        activeElement.tagName === "TEXTAREA" ||
        activeElement.isContentEditable);
    if (isTyping) return;

    if (event.key === "ArrowLeft" && currentPage > 1) {
      event.preventDefault();
      goToPreviousPage();
    } else if (event.key === "ArrowRight" && currentPage < totalPages) {
      event.preventDefault();
      goToNextPage();
    }
  };

  // Attach and clean up keydown event listener
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentPage, totalPages, goToPreviousPage, goToNextPage]);

  return (
    <div
      className="d-flex align-items-center justify-content-between mb-4 mt-5"
      style={{ gap: "0.5rem" }}
    >
      <div className="d-flex align-items-center" style={{ gap: "0.5rem" }}>
        <Dropdown onSelect={(e) => setFilterType(e)} className="me-2">
          <Dropdown.Toggle
            variant="outline-secondary"
            id="filter-type-dropdown"
            size="sm"
          >
            {filterType}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey="All">All</Dropdown.Item>
            <Dropdown.Item eventKey="Directories">Directories</Dropdown.Item>
            <Dropdown.Item eventKey="Documents">Documents</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {totalItems > itemsPerPage && (
          <div
            className="d-flex align-items-center me-2"
            style={{ gap: "0.25rem" }}
          >
            <Button
              variant="secondary"
              onClick={goToFirstPage}
              disabled={currentPage === 1}
              aria-label="First page"
              size="sm"
            >
              <FaAngleLeft />
            </Button>
            <Button
              variant="secondary"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
              aria-label="Previous page"
              size="sm"
            >
              Prev
            </Button>
            <Button
              variant="secondary"
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
              aria-label="Next page"
              size="sm"
            >
              Next
            </Button>
            <Button
              variant="secondary"
              onClick={goToLastPage}
              disabled={currentPage === totalPages}
              aria-label="Last page"
              size="sm"
            >
              <FaAngleRight />
            </Button>
          </div>
        )}
        <Dropdown
          onSelect={(e) => handleItemsPerPageChange(Number(e))}
          className="me-2"
        >
          <Dropdown.Toggle
            variant="outline-secondary"
            id="items-per-page-dropdown"
            size="sm"
          >
            {itemsPerPage}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {itemsPerPageOptions.map((option) => (
              <Dropdown.Item key={option} eventKey={option}>
                {option}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="d-flex align-items-center" style={{ gap: "0.5rem" }}>
        <div className="mb-2">
          {`Showing ${Math.min(
            (currentPage - 1) * itemsPerPage + 1,
            totalItems
          )} to ${Math.min(
            currentPage * itemsPerPage,
            totalItems
          )} of ${totalItems} items`}
        </div>
      </div>
    </div>
  );
};

FilterAndPaginationControls.propTypes = {
  filterType: PropTypes.string.isRequired,
  setFilterType: PropTypes.func.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  itemsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  totalItems: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  goToFirstPage: PropTypes.func.isRequired,
  goToPreviousPage: PropTypes.func.isRequired,
  goToNextPage: PropTypes.func.isRequired,
  goToLastPage: PropTypes.func.isRequired,
  handleItemsPerPageChange: PropTypes.func.isRequired,
};

export default FilterAndPaginationControls;
